/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import canvg from 'canvg';
import './FloorplanGraphic.css';
import GlobalConfig from '../../utils/GlobalConfig';

class FloorplanGraphic extends Component {
  state = {
    redrawn: []
  };

  componentDidMount() {
    this.convertSVG2Canvas();
  }

  componentDidUpdate(prevProps, prevState) {
    const { redrawn } = this.state;
    const { incrementGraphicsReady, svgOptionList } = this.props;

    if (JSON.stringify(prevState.redrawn) !== JSON.stringify(redrawn)
      && redrawn.length >= svgOptionList.length) {
      incrementGraphicsReady();
    }
  }

  convertSVG2Canvas = () => {
    const {
      modelRID,
      communityRID,
      selectedFloor,
      floorIndex,
      baseImage
    } = this.props;

    const baseImageRequired = GlobalConfig.get(GlobalConfig.Key.CLIENT_NAME) === 'oakwood';

    const baseImageUrl = baseImageRequired
      ? `https://s3-us-west-1.amazonaws.com/${GlobalConfig.get(GlobalConfig.Key.S3_BUCKET)}/${communityRID}/${modelRID}/svg/std/floor_${floorIndex + 1}/${baseImage}-01.svg?origin=bimairedev.app`
      : `https://s3-us-west-1.amazonaws.com/${GlobalConfig.get(GlobalConfig.Key.S3_BUCKET)}/${modelRID}/svg/std/floor_${floorIndex}.svg?origin=bimairedev.app`;

    canvg(
      `canvas-${selectedFloor}`,
      baseImageUrl,
      {
        useCORS: true,
        ignoreMouse: true,
        forceRedraw: () => this.forceRedraw(baseImage)
      }
    );
    this.svgOptionsToCanvas();
  };

  forceRedraw = (svgHref) => {
    const { redrawn } = this.state;

    if (!redrawn.includes(svgHref)) {
      this.setState(prevState =>
        ({ redrawn: [...prevState.redrawn, svgHref] }));

      return true;
    }
    return false;
  }

  listCanvas = () => {
    const {
      svgOptionList,
      selectedFloor,
    } = this.props;

    return svgOptionList.map(svgHref => (
      <canvas key={svgHref} id={`${svgHref}-${selectedFloor}`} />
    ));
  }

  svgOptionsToCanvas = () => {
    const {
      modelRID,
      communityRID,
      svgOptionList,
      floorIndex,
      selectedFloor,
      nestedUnderCommRidS3
    } = this.props;

    const setFloorIndex = GlobalConfig.get(GlobalConfig.Key.CLIENT_NAME) === 'oakwood'
      ? floorIndex + 1
      : floorIndex;

    const setCommunityUrlFragment = nestedUnderCommRidS3
      ? `${communityRID}/`
      : '';

    svgOptionList.forEach((svgHref) => {
      const url = `https://s3-us-west-1.amazonaws.com/${GlobalConfig.get(GlobalConfig.Key.S3_BUCKET)}/${setCommunityUrlFragment}${modelRID}/svg/std/floor_${setFloorIndex}/${svgHref}-01.svg?origin=bimairedev.app`;
      // console.log('floorIndex & url', floorIndex, url);
      canvg(
        `${svgHref}-${selectedFloor}`,
        url,
        {
          useCORS: true,
          ignoreMouse: true,
          forceRedraw: () => this.forceRedraw(svgHref)
        }
      );
    });
  }

  render() {
    const { selectedFloor } = this.props;
    // console.log('selectedFloor in render', selectedFloor);

    return (
      <div className="FloorplanGraphic" id={selectedFloor}>
        <canvas id={`canvas-${selectedFloor}`} />
        {this.listCanvas(selectedFloor)}
      </div>
    );
  }
}

FloorplanGraphic.propTypes = {
  baseImage: PropTypes.string.isRequired,
  selectedFloor: PropTypes.string.isRequired,
  svgOptionList: PropTypes.array.isRequired,
  communityRID: PropTypes.number.isRequired,
  modelRID: PropTypes.number.isRequired,
  nestedUnderCommRidS3: PropTypes.bool.isRequired
};

export default FloorplanGraphic;
