import React, { Component } from 'react';
import PropTypes from 'prop-types';
import html2canvas from 'html2canvas';
import {
  BlobProvider,
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import AvenirLight from '../../fonts/Avenir-Light.ttf';
import AvenirMedium from '../../fonts/Avenir-Roman.ttf';
import styles from './PDFDownloader.module.css';
import Api from '../../utils/Api/Api';
import FloorplanGraphic from '../FloorplanGraphic/FloorplanGraphic';
import KovaData from '../../utils/KovaData/KovaData';
import SvgBuilder from '../../utils/SvgBuilder/SvgBuilder';
import S3Utils from '../../utils/S3/S3';
import ProgressBar from '../../shared/ProgressBar/ProgressBar';
import { ClientSettingsConsumer } from '../../context/ClientSettingsContext';


import {
  selectCommunityRID,
  selectCommunityDetails
} from '../../redux/community/community.selectors';

import {
  selectOptionDefaults
} from '../../redux/colorSelector/colorSelector.selectors';

import {
  selectModelRID,
  selectConfigData,
  selectModelRules,
  selectAvailFloors,
  selectBasePrice,
  selectModelDetails,
  selectKeyMap
} from '../../redux/model/model.selectors';

import {setDownloadURL, toggleTriggerPrint} from '../../redux/app/app.actions';
import GlobalConfig from '../../utils/GlobalConfig';

Font.register({ family: 'Avenir-Light', src: AvenirLight });
Font.register({ family: 'Avenir-Medium', src: AvenirMedium });

const disclaimerText = {
  oakwood: 'Elevations and floor plan contained herein are not to scale and are graphic illustrations for marketing and presentation purposes only. Actual floor plans and all materials may vary prior to or during construction. Some features shown may be optional. Oakwood Homes reserves the right to substitute materials and components of similar quality,and to change features, options, and architectural details without prior notice. Accordingly, neither these materials, nor any communications made or given in connection with these materials, may be deemed to constitute any representation of warranty, contract, or guarantee or may otherwise be relied upon by any person or entity unless conveyed in written form. Plans, Pricing and Features are subject to change without notice. Elevations may show options that are not standard. Square footage values and dimensions are approximate and may vary.',
  arbor: "Prices, plans, and terms are effective on the date of publication and subject to change without notice. Square footage shown is only an estimate and actual square footage will differ. Depictions of homes or other features are artist conceptions. Hardscape, landscape, and other items shown may be decorator suggestions that are not included in the purchase price and availability may vary. Plans to build out this neighborhood as proposed are subject to change without notice. 3D floor plan rendering is for illustrative purposes only and represents a concept of a standard model of this home and may not represent the home that would be available for purchase at the purchase price shown. The rendering may represent the base model of this home without any options or upgrades, or may depict options, upgrades or features that are available at an additional cost. Furniture placement and flooring is artist's conception and is not intended to show specific detailing. Floor plans are the property of Arbor Homes."
};

const PDFStyles = StyleSheet.create({
  page: {
    backgroundColor: '#fff',
    flexDirection: 'column',
    padding: '2%, 4%',
    fontFamily: 'Avenir-Medium',
    maxWidth: '800px'
  },
  heading: {
    border: '1pt solid #57585a',
    flexDirection: 'row',
    marginBottom: '2pt',
    maxWidth: '700px',
  },
  headingTextContainer: {
    width: '65%',
    flexDirection: 'column',
    padding: '0%, 2pt',
    textAlign: 'center',
    height: '100%'
  },
  headingText1: {
    fontFamily: 'Avenir-Medium',
    fontSize: '20pt',
    maxWidth: '700px',
  },
  headingText2: {
    borderBottom: '1pt solid #000',
    fontFamily: 'Avenir-Light',
    fontSize: '16pt',
    textTransform: 'capitalize',
  },
  headingDetails: {
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%'
  },
  modelNameText: {
    fontFamily: 'Avenir-Medium',
    fontSize: '22pt',
    fontWeight: '500',
    textTransform: 'uppercase',
    flex: 1,
  },
  headingDetailsSpec: {
    flexDirection: 'row',
    fontFamily: 'Avenir-Light',
    fontSize: '12pt',
    flex: 2,
  },
  customModelPrice: {
    width: '100%',
    fontSize: '24pt',
    textAlign: 'right'
  },
  displayImg: {
    marginTop: '5pt',
    marginBottom: '5pt',
    height: 'auto',
    width: '100%',
    justifyContent: 'center',
    border: '2px solid #eee'
  },
  selectionHeading: {
    backgroundColor: '#000',
    color: '#fff',
    fontFamily: 'Avenir-Medium',
    fontSize: '18pt',
    textAlign: 'center'
  },
  optionList: {
    flex: '1',
    fontFamily: 'Avenir-Light',
    fontSize: '12pt',
    marginTop: '2pt',
    padding: '0 1%'
  },
  disclaimer: {
    fontFamily: 'Avenir-Light',
    fontSize: '7pt',
    lineHeight: '1.25pt',
    marginBottom: '2pt',
    padding: '0 1%'
  },
  footer: {
    flexDirection: 'row',
    fontFamily: 'Avenir-Light',
    fontSize: '7pt',
    justifyContent: 'space-between'
  }
});

class PDFDownloader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      floorplanImgs: [],
      graphicsReady: 0,
      status: '',
      allFloorSvgs: null,
      listOfValidOptions: null,
    };
  }

  componentDidMount() {
    this.compileSvgListByFloor();
    this.setStatus('floorplan-graphics');
  }

  componentDidUpdate(prevProps, prevState) {
    const { graphicsReady, status } = this.state;
    const { availFloors, configData } = this.props;

    if (prevState.status === 'floorplan-graphics' && status === 'pdf') {
      this.setFloorplanImg();
    }

    if (prevState.graphicsReady === availFloors.length - 2
      && graphicsReady === availFloors.length - 1) {
      this.setStatus('pdf');
    }

    if (configData.SelectedOptions !== prevProps.configData.SelectedOptions) {
      this.compileSvgListByFloor();
    }
  }

  listPages = (theme, showPriceOnPDF, nestedUnderCommRidS3, kovaKeyMap) => {
    const {
      availFloors,
      configData,
      basePrice,
      communityDetails,
      modelDetails
    } = this.props;

    const setBeds = (modelDetails && `${modelDetails[kovaKeyMap.numBeds]} Bed | `) || '';
    const setBaths = (modelDetails && `${modelDetails[kovaKeyMap.numBaths]} Bath | `) || '';
    const setSqf = (modelDetails && `${modelDetails[kovaKeyMap.numSqf]} Sq Ft`) || '';

    const modelName = configData[kovaKeyMap.modelName];

    return availFloors.map((page, i) => (
      <Page key={`${page.ID}-${page.Type}`} size="A4" style={PDFStyles.page}>
        <View style={PDFStyles.heading}>
          <View
            style={{
              height: '100%',
              width: '35%',
              backgroundColor: theme.primaryColor,
              flexDirection: 'column',
              justifyContent: 'center'
            }}
          >
            <Image
              src={`./logo/${theme.logo}`}
              style={{ maxWidth: '95%' }}
            />
          </View>
          <View style={PDFStyles.headingTextContainer}>
            <Text style={PDFStyles.headingText1}>{`${communityDetails.MarketingName || ''}`}</Text>
            <Text style={PDFStyles.headingText2}>{`${communityDetails.City || ''}, ${communityDetails.State || ''}`}</Text>
            <View style={PDFStyles.headingDetails}>
              <Text style={PDFStyles.modelNameText}>
                {`${modelName}`}
              </Text>
              <Text style={PDFStyles.headingDetailsSpec}>
                {`${setBeds}${setBaths}${setSqf}`}
              </Text>
            </View>
          </View>
        </View>
        {showPriceOnPDF && (
          <Text style={PDFStyles.customModelPrice}>
            {`Starting at: $${KovaData.formatNumberWithComma(basePrice)}`}
          </Text>
        )}
        <Image src={this.determineImgSrc(page, nestedUnderCommRidS3)} style={PDFStyles.displayImg} />
        <View style={PDFStyles.selectionHeading}>
          <Text style={{ textTransform: 'capitalize' }}>{page.Description}</Text>
        </View>
        <View style={PDFStyles.optionList}>
          <Text>{(page.Description !== 'Elevation' || GlobalConfig.get(GlobalConfig.Key.CLIENT_NAME) !== 'oakwood') && this.createOptionText(page.Description, page.Index)}</Text>
        </View>
        <View style={PDFStyles.disclaimer}>
          <Text>
            {disclaimerText[GlobalConfig.get(GlobalConfig.Key.CLIENT_NAME)]}
          </Text>
        </View>
        <View style={PDFStyles.footer}>
          <Text>{`Page ${i + 1}`}</Text>
          <Text>{new Date().toLocaleDateString()}</Text>
        </View>
      </Page>
    ));
  }

  compileSvgListByFloor = async () => {
    const allConfigData = await this.getConfigDataAllFloors();
    this.generateArraySelOptText(allConfigData);
    const allFloorSvgs = this.generateAllFloorSvgList(allConfigData);

    this.setState({ allFloorSvgs });
  }

  getConfigDataAllFloors = async () => {
    const {
      communityRID,
      modelRID,
      configData,
      availFloors
    } = this.props;

    const output = {};

    const floors = availFloors.filter(floor => floor.Description !== 'Elevation');
    const promises = floors.map(floor => Api.postConfigDataUpdate(
      communityRID,
      modelRID,
      floor.Index,
      'False',
      configData.SelectedOptions
    ));
    const results = await Promise.all(promises);
    floors.forEach((floor, index) => {
      output[floor.Index] = results[index];
    });

    return output;
  };

  generateArraySelOptText = (objOfConfigData) => {
    const listOfValidOptions = {};

    Object.keys(objOfConfigData).forEach((floorIdx) => {
      const selOptsByFloor = KovaData
        .findAllSelectedOptions(objOfConfigData[floorIdx].Options)
        .filter(opt => opt !== 'Not Selected' && opt !== '- Not Selected -');

      listOfValidOptions[floorIdx] = selOptsByFloor;
    });

    this.setState({ listOfValidOptions });
    return listOfValidOptions;
  }

  generateAllFloorSvgList = (allConfigData) => {
    const { modelRules } = this.props;
    const output = {};

    Object.keys(allConfigData).forEach((floor) => {
      const result = this.buildSvgList(
        allConfigData[floor],
        floor,
        modelRules
      );
      output[floor] = result;
    });

    return output;
  };

  buildSvgList = (config, selectedFloor, modelRules) => {
    const svgBuilder = new SvgBuilder(
      config.Options,
      selectedFloor,
      modelRules
    );

    const svgOptionList = svgBuilder.build();
    return svgOptionList;
  }

  handleButtonClick = () => {
    const { status } = this.state;

    if (!status) {
      this.setStatus('floorplan-graphics');
    }
  }

  createDocument = (theme, showPriceOnPDF, nestedUnderCommRidS3, kovaKeyMap) => (
    <Document>
      {this.listPages(theme, showPriceOnPDF, nestedUnderCommRidS3, kovaKeyMap)}
    </Document>
  )

  setElevationImage = (communityRID, selOpts) => {
    const { keyMap } = this.props;

    return (
      this.isBuildTypeComm(communityRID)
        ? `${KovaData.parseOption(selOpts[keyMap.elevation])}_${KovaData.parseOption(selOpts.BUILDINGTYPE)}`
        : KovaData.parseOption(selOpts[keyMap.elevation])
    );
  }

  determineImgSrc = (page, nestedUnderCommRidS3) => {
    const { modelRID, communityRID, configData } = this.props;
    const { floorplanImgs } = this.state;

    const setCommunityUrlFragment = nestedUnderCommRidS3
      ? `${communityRID}/`
      : '';

    const elevImage = this.setElevationImage(
      communityRID,
      configData.SelectedOptions
    );

    return (page.Description === 'Elevation' && GlobalConfig.get(GlobalConfig.Key.CLIENT_NAME) === 'oakwood')
      ? `${GlobalConfig.get(GlobalConfig.Key.SET_PROTOCOL)}s3-us-west-1.amazonaws.com/${GlobalConfig.get(GlobalConfig.Key.S3_BUCKET)}/${setCommunityUrlFragment}${modelRID}/img/${elevImage.toLowerCase()}.jpg?origin=bimairedev.app`
      : `${floorplanImgs[page.Index]}`;
  }

  setFloorplanImg = async () => {
    setTimeout(async () => {
      const { availFloors } = this.props;

      const floorplanImgCalls = availFloors.map(
        floor => this.grabFloorplanImg(floor.ID)
      );

      const imgSrc = await Promise.all([...floorplanImgCalls]);

      if (imgSrc) {
        this.setState({ floorplanImgs: [...imgSrc] });
      }
    }, 1500);
  }

  grabFloorplanImg = async (id) => {
    let input;

    if (id.split('-')[1] === 'E') {
      input = document.querySelector('#exteriorStageComponent');
    } else {
      input = document.getElementById(id);
    }

    let imgData;

    if (input) {
      const canvas = await html2canvas(
        input,
        { useCORS: true, allowTaint: false }
      );

      imgData = canvas.toDataURL('image/png');
    }

    return imgData;
  }

  createOptionText = (description, index) => {
    const { listOfValidOptions } = this.state;
    if (description === 'Elevation') {
      return this.createElevationOptionText();
    }
    return `Selected Options: ${listOfValidOptions[index].join(', ')}`;
  }

  createElevationOptionText = () => {
    const { elevationOptions } = this.props;

    const result = Object.keys(elevationOptions).reduce((accum, currVal) => {
      if (elevationOptions[currVal]) {
        // const optText = elevationOptions[currVal].text;
        accum.push(`${currVal.charAt(0).toUpperCase()}${currVal.slice(1)}: ${elevationOptions[currVal].text} `);
      }
      return accum;
    }, []);

    return `Selected Options: ${result.join(', ')}`;
  }

  setStatus = (status) => {
    this.setState({ status });
  }

  isBuildTypeComm = (communityRID) => {
    const commsWithBT = [219];

    return GlobalConfig.get(GlobalConfig.Key.CLIENT_NAME) === 'oakwood' && commsWithBT.includes(communityRID);
  }

  setBaseImage = (communityRID, selOpts) => {
    const { keyMap } = this.props;

    return this.isBuildTypeComm(communityRID)
      ? KovaData.parseOption(selOpts.BUILDINGTYPE)
      : KovaData.parseOption(selOpts[keyMap.elevation]);
  }

  listFloorplanGraphics = (nestedUnderCommRidS3) => {
    const {
      availFloors,
      communityRID,
      modelRID,
      configData
    } = this.props;

    const {
      allFloorSvgs
    } = this.state;

    const baseImage = this.setBaseImage(
      communityRID,
      configData.SelectedOptions
    );

    return availFloors
      .filter(floor =>
        floor.Description !== 'Elevation')
      .map(floor => (
        <FloorplanGraphic
          key={`${floor.ID}-${floor.Index}`}
          incrementGraphicsReady={this.incrementGraphicsReady}
          communityRID={communityRID}
          modelRID={modelRID}
          baseImage={baseImage}
          selectedFloor={floor.ID}
          setStatus={this.setStatus}
          floorIndex={floor.Index}
          svgOptionList={allFloorSvgs[floor.Index]}
          nestedUnderCommRidS3={nestedUnderCommRidS3}
        />
      ));
  }

  incrementGraphicsReady = () => {
    this.setState(prevState =>
      ({ graphicsReady: prevState.graphicsReady + 1 }));
  }

  finishDownload = () => {
    const { toggleTriggerPrint } = this.props;
    this.setState({
      floorplanImgs: [],
      graphicsReady: 0,
      status: ''
    });
    toggleTriggerPrint();
  }

  resetPDF = () => (
    <BlobProvider document={this.createDocument()}>
      {({ loading }) => {
        if (!loading) {
          this.finishDownload();

          return true;
        }

        return '';
      }}
    </BlobProvider>
  );

  handleGeneratedPDF = async (file) => {
    try {
      const { roomId, socket } = this.props;
      const fileName = await this.uploadPDF(file);
      const fileLink = `https://${GlobalConfig.get(GlobalConfig.Key.S3_BUCKET)}.s3-${GlobalConfig.get(GlobalConfig.Key.S3_REGION)}.amazonaws.com/${fileName}`;

      const event = {
        data: fileLink,
        name: 'open pdf',
        roomId
      };
      socket.emit('open pdf', event);
    } catch (err) {
      // TODO: Replace console.error() with reusable Alert/Error component
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }

  uploadPDF = async (file) => {
    const { roomId } = this.props;
    const key = `docs/brochure-${roomId}.pdf`;
    const s3 = S3Utils.connect();
    await S3Utils.putObject('application/pdf', file, key, s3);

    return key;
  }

  render() {
    const { setDownloadURL } = this.props;
    const { floorplanImgs, status, allFloorSvgs } = this.state;

    return (
      <div className={styles.PDFDownloader}>
        <ClientSettingsConsumer>
          {({
            theme, printToS3, showPriceOnPDF, nestedUnderCommRidS3, kovaKeyMap
          }) => (
            floorplanImgs.length > 0 && (
              <BlobProvider document={this.createDocument(theme, showPriceOnPDF, nestedUnderCommRidS3, kovaKeyMap)}>
                {({ blob, loading, url }) => {
                  if (!loading) {
                    if (printToS3) {
                      const file = new File([blob], 'brochure.pdf');
                      this.handleGeneratedPDF(file);
                      // window.open(url);
                    } else {
                      setDownloadURL(url);
                      //window.open(url);
                    }
                    this.finishDownload();
                    return true;
                  }
                  return '';
                }}
              </BlobProvider>
            )
          )}
        </ClientSettingsConsumer>
        <ClientSettingsConsumer>
          {({ nestedUnderCommRidS3 }) => (
            <>
              {(status === 'floorplan-graphics' || status === 'pdf') && allFloorSvgs && this.listFloorplanGraphics(nestedUnderCommRidS3)}
              {status ? (
                <ProgressBar />
              ) : null}
            </>
          )}
        </ClientSettingsConsumer>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  modelRID: selectModelRID,
  configData: selectConfigData,
  communityRID: selectCommunityRID,
  communityDetails: selectCommunityDetails,
  modelRules: selectModelRules,
  availFloors: selectAvailFloors,
  basePrice: selectBasePrice,
  modelDetails: selectModelDetails,
  elevationOptions: selectOptionDefaults,
  keyMap: selectKeyMap
});

const mapDispatchToProps = dispatch => ({
  toggleTriggerPrint: () => dispatch(toggleTriggerPrint()),
  setDownloadURL: downloadURL => dispatch(setDownloadURL(downloadURL)),
});

PDFDownloader.propTypes = {
  roomId: PropTypes.string,
  socket: PropTypes.object.isRequired,
  modelDetails: PropTypes.object.isRequired,
  modelRID: PropTypes.number.isRequired,
  communityRID: PropTypes.number.isRequired,
  configData: PropTypes.object.isRequired,
  availFloors: PropTypes.array.isRequired,
  toggleTriggerPrint: PropTypes.func.isRequired,
  elevationOptions: PropTypes.object.isRequired,
  modelRules: PropTypes.object.isRequired,
  keyMap: PropTypes.object.isRequired,
  setDownloadURL: PropTypes.func.isRequired,
};

PDFDownloader.defaultProps = {
  roomId: undefined
};

export default connect(mapStateToProps, mapDispatchToProps)(PDFDownloader);
