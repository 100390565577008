import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import styles from './ElevationOption.module.css';

import {
  selectModelRID,
  selectConfigData,
  selectModelOptions,
  selectKeyMap
} from '../../redux/model/model.selectors';

import { ClientSettingsContext } from '../../context/ClientSettingsContext';
import GlobalConfig from '../../utils/GlobalConfig';

const ElevationOption = ({
  elev,
  modelRID,
  handleOptionItemClick,
  keyMap
}) => {
  const ClientSettings = useContext(ClientSettingsContext);
  const { defaultCommunityRID } = ClientSettings;

  const parsedFilename = elev.ID.split('|')[0].toLowerCase();
  return (
    <div className={styles.elevationOption} key={elev.displayText} aria-hidden>
      <div className={`${styles.overlayContainer} ${elev.Selected ? styles.boxSelected : ''}`}>
        <div className={styles.elevImage}>
          <img
            onClick={event => handleOptionItemClick(
              event,
              {
                clickType: 'optionChange',
                option: elev.ID,
                categoryId: keyMap.elevation,
                choiceType: 'MultiChoice'
              }
            )}
            src={`https://s3-us-west-1.amazonaws.com/${
              GlobalConfig.get(GlobalConfig.Key.S3_BUCKET)
            }/${defaultCommunityRID}/${modelRID}/img/${parsedFilename}.jpg`}
            alt="elevation"
            aria-hidden
          />
        </div>
      </div>
      <div className={styles.textDisplay}>
        { elev.Text }
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  configData: selectConfigData,
  modelRID: selectModelRID,
  modelOptions: selectModelOptions,
  keyMap: selectKeyMap
});

ElevationOption.propTypes = {
  modelRID: PropTypes.number.isRequired,
  elev: PropTypes.object.isRequired,
  keyMap: PropTypes.object.isRequired,
  handleOptionItemClick: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(ElevationOption);
