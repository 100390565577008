/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styles from './SelectionsMenu.module.css';
import SelectionsGroup from './SelectionsGroup/SelectionsGroup';
import TouchscreenToggleBar from '../../shared/TouchscreenToggleBar/TouchscreenToggleBar';
import { ClientSettingsConsumer } from '../../context/ClientSettingsContext';

import {
  selectModelOptions,
  selectModelRules,
  selectFloor
} from '../../redux/model/model.selectors';

class SelectionsMenu extends Component {
  state = {
    menuOpen: true
  };

  handleExpand = () =>
    this.setState(prevState => ({ menuOpen: !prevState.menuOpen }));

  createMultiChoiceOptions = (categoryBlacklist) => {
    const {
      handleOptionItemClick,
      modelRules,
      selectedFloor,
      quickMoveIn,
      modelOptions
    } = this.props;

    const blacklist = modelRules && modelRules.selectionsBlacklist
      ? modelRules.selectionsBlacklist[selectedFloor]
      : [];

    const multiChoiceOpts = Object.keys(modelOptions).map((option) => {
      if (
        modelOptions[option].Type === 'MultiChoice'
        && (!categoryBlacklist
          || (categoryBlacklist
            && !categoryBlacklist.includes(modelOptions[option].ID)))
      ) {
        return (
          <div className={styles.section} key={modelOptions[option].ID}>
            <SelectionsGroup
              menuHeaderText={modelOptions[option].Text}
              menuOptions={modelOptions[option].Choices}
              handleOptionItemClick={handleOptionItemClick}
              optionCategory={option}
              choiceType={modelOptions[option].Type}
              blacklist={blacklist || []}
              quickMoveIn={quickMoveIn}
            />
          </div>
        );
      }
    });
    return multiChoiceOpts.filter(Boolean);
  };

  createSingleChoiceOptions = () => {
    const {
      handleOptionItemClick,
      modelRules,
      selectedFloor,
      quickMoveIn,
      modelOptions
    } = this.props;

    const blacklist = modelRules && modelRules.selectionsBlacklist
      ? modelRules.selectionsBlacklist[selectedFloor]
      : [];

    const singleChoiceOpts = Object.keys(modelOptions)
      .map((option) => {
        if (modelOptions[option].Type === 'SingleChoice') {
          return modelOptions[option];
        }
      })
      .filter(Boolean);

    return singleChoiceOpts.length > 0 ? (
      <div className={styles.section}>
        <SelectionsGroup
          key="single-choice-options"
          menuHeaderText="Single Choice Options"
          menuOptions={singleChoiceOpts}
          handleOptionItemClick={handleOptionItemClick}
          optionCategory={false}
          blacklist={blacklist || []}
          choiceType="SingleChoice"
          quickMoveIn={quickMoveIn}
        />
      </div>
    ) : null;
  };

  render() {
    const { menuOpen } = this.state;

    return (
      <ClientSettingsConsumer>
        {({
          touchscreenEnabled,
          colorSelectorEnabled,
          multiChoiceOptExclusionList
        }) => (
          <div
            className={
              menuOpen ? styles.selectionsMenu : styles.closedSelectionsMenu
            }
            style={{
              borderLeft: `${!colorSelectorEnabled && 'none'}`,
              borderRight: `${!colorSelectorEnabled && 'none'}`,
              paddingRight: `${!colorSelectorEnabled && 'none'}`
            }}
          >
            {touchscreenEnabled && (
              <TouchscreenToggleBar
                menuOpen={menuOpen}
                clickHandler={this.handleExpand}
                tabText="selection options"
              />
            )}
            <div
              // eslint-disable-next-line no-nested-ternary
              className={
                menuOpen
                  ? touchscreenEnabled
                    ? styles.touchCardContainer
                    : styles.webCardContainer
                  : touchscreenEnabled
                    ? styles.closedTouchCardContainer
                    : styles.closedWebCardContainer
              }
              style={{ border: `${!touchscreenEnabled && 'none'}` }}
            >
              {menuOpen && this.createMultiChoiceOptions(multiChoiceOptExclusionList)}
              {menuOpen && this.createSingleChoiceOptions()}
            </div>
          </div>
        )}
      </ClientSettingsConsumer>
    );
  }
}

SelectionsMenu.propTypes = {
  handleOptionItemClick: PropTypes.func.isRequired
};

const mapStateToProps = createStructuredSelector({
  modelOptions: selectModelOptions,
  modelRules: selectModelRules,
  selectedFloor: selectFloor
});

export default connect(mapStateToProps)(SelectionsMenu);
