/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
export const formatPriceAsNum = (priceAsString) => {
  const regex = /[^\d.]/g; // removes $, spaces, and comma

  return priceAsString
    ? Number(priceAsString.replace(regex, ''))
    : 0;
};

export const parseValidFloors = floorObj => (
  Object.keys(floorObj)
    .map(floor => floorObj[floor])
    .filter(floor => !floor.Description.includes('Trim'))
);

const fetchDataValue = (key, optValue) => {
  switch (key) {
    case 'price':
      return formatPriceAsNum(optValue);
    default:
      break;
  }
};

export const generatePriceList = (configData, key) => {
  const capitalizedKey = `${key.charAt(0).toUpperCase()}${key.substring(1)}`;
  return Object.keys(configData).reduce((accum, currVal) => {
    if (configData[currVal].Type === 'MultiChoice') {
      accum[configData[currVal].ID] = configData[currVal].Choices.map(opt => ({
        optionID: opt.ID,
        [key.toLowerCase()]: fetchDataValue(key, opt[capitalizedKey]),
        optionType: 'MultiChoice'
      }));
    } else {
      accum[currVal] = fetchDataValue(key, configData[currVal][capitalizedKey]);
      return accum;
    }
    return accum;
  }, {});
};

export const generateKeyMap = (configData) => {
  const options = configData.Options;
  return Object.keys(options).reduce((accum, currVal) => {
    const optGroup = options[currVal];
    if (optGroup.Text.toLowerCase() === 'elevation') {
      accum.elevation = optGroup.ID;
    }
    if (optGroup.Text.toLowerCase() === 'garage') {
      accum.garage = optGroup.ID;
    }
    return accum;
  }, {});
};

const formatSelectedOption = option => option.replace(/\|\|/g, '').toLowerCase();

export const findAllSelectedOptions = (optionsData) => {
  const output = [];

  Object.keys(optionsData).forEach((option) => {
    const currentOption = optionsData[option];
    if (currentOption.Type === 'MultiChoice') {
      currentOption.Choices.forEach((singleOpt) => {
        if (singleOpt.Selected) {
          if (
            singleOpt.ID.includes('cabs')
            || singleOpt.ID.includes('Chrome')
          ) {
            const splitOpt = singleOpt.ID.split('|')[0];
            output.push(splitOpt);
          } else {
            output.push(formatSelectedOption(singleOpt.ID));
          }
        }
      });
    } else if (currentOption.Selected) {
      output.push(formatSelectedOption(currentOption.ID));
    }
  });

  return output;
};

export const findTotalOfSelectedOptions = (selectedOpts, priceList) => Object.keys(selectedOpts).reduce((accum, currVal) => {
  if (Array.isArray(priceList[currVal])) {
    let selected = priceList[currVal]
      .find(opt => opt.optionID === selectedOpts[currVal]);
    // some quick move in's have options that are not in the pricelist. so they will be ignored for now
    // -William, worksheets branch, Thurs sept 3rd
    if (!selected) {
      selected = { price: 0 };
    }

    accum += selected.price;
  } else if (Number.isInteger(priceList[currVal])) {
    accum += priceList[currVal];
  } else {
    return 0;
  }
  return accum;
}, 0);
