/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Dashboard from '../Dashboard/Dashboard';
import ColorSelector from '../ColorSelector/ColorSelector';
import styles from './App.module.css';
import handleToggleView from '../../utils/stateChanges';
import modelRuleConfig from '../../config/modelRuleConfig';
import Api from '../../utils/Api/Api';
import KovaData from '../../utils/KovaData/KovaData';
import LoadingIndicator from '../../shared/LoadingIndicator/LoadingIndicator';
import PDFDownloader from '../PDFDownloader/PDFDownloader';
import KovaPDFDownloader from '../KovaPDFDownloader/KovaPDFDownloader';
import Socket from '../../utils/Socket/Socket';
import SaveWorksheet from '../../utils/SaveWorksheet/SaveWorksheet';


// SELECTORS
import {
  selectModelDetails,
  selectAvailFloors,
  selectModel,
  selectConfigData,
  selectOptionTotal,
  selectPriceList,
  selectFloor,
  selectModelRules,
  selectShowElevation
} from '../../redux/model/model.selectors';

import {selectDownloadURL, selectTriggerPrint} from '../../redux/app/app.selectors';

// ACTIONS
import {
  setModelRID,
  setConfigData,
  setModelRules,
  setSelectedFloor,
  setModelDetails,
  setPriceList,
} from '../../redux/model/model.actions';

import {
  setCommunityRID,
  setCommunityDetails,
  setCommunityModelList,
  setCommunityDirectory,
  setDropdownCommRID
} from '../../redux/community/community.actions';

import {
  setShowKovaStage,
  toggleSideMenu,
  toggleResetView,
  toggleTriggerPrint,
  setDownloadURL
} from '../../redux/app/app.actions';
import DownloadDialog from '../../shared/DownloadDialog/DownloadDialog';

import GlobalConfig from '../../utils/GlobalConfig';

const queryString = require('query-string');
const { version } = require('../../../package.json');

class App extends Component {
  state = {
    modelRID: null,
    communityRID: null,
    isActiveAdult: null,
    filteredOptImgs: null,
    selectedFloor: null,
    optionTotal: null,
    elevationOptions: null,
    roomId: null,
    savingStatus: '',
    updatingData: false,
    quickMoveIn: false,
    downloadURL: null
  };

  socket = null;

  async componentDidMount() {
    const {
      touchscreenEnabled,
      showKovaStage,
      loadModelRules,
      defaultCommunityRID,
      defaultModelRID,
      setModelRID,
      setCommunityRID,
      setCommunityDetails,
      setCommunityModelList,
      setShowKovaStage,
      setModelRules,
      setSelectedFloor,
      setModelDetails,
      setCommunityDirectory,
      setPriceList,
      setDropdownCommRID,
      communityDropdownList,
      showCommunityDropdownList,
      clientFloorOnAppLoad
    } = this.props;

    let modelRules;
    const query = queryString.parse(window.location.search);
    // Change all query params to lowercase to catch typos
    const newQry = Object.keys(query).reduce((accum, currVal) => {
      accum[currVal.toLowerCase()] = query[currVal];
      return accum;
    }, {});
    console.log('documentElement', document.documentElement.style);

    if (newQry.isactiveadult) {
      console.log('documentElement', document.documentElement);
      document.documentElement.style.setProperty('--color-a', '#621244');
      document.documentElement.style.setProperty('--color-a-light', '#F2E2EC');
      document.documentElement.style.setProperty('--color-b', '#50A3A2');
      document.documentElement.style.setProperty('--font-family-a', 'Optima');
      this.setState({ isActiveAdult: true });
    }

    const modelRID = Number(newQry.modelrid) || defaultModelRID;
    const communityRID = Number(newQry.commrid) || defaultCommunityRID;
    const roomId = newQry.roomid || 'testing';
    const quickMove = newQry.quickmove || false;
    const worksheet = newQry.slswshrid || false;

    if (loadModelRules) {
      modelRules = modelRuleConfig.getRules(
        GlobalConfig.get(GlobalConfig.Key.CLIENT_NAME),
        communityRID,
        modelRID
      );
    }

    const selectedFloor = modelRules && modelRules.floorOnAppLoad && Number.isInteger(modelRules.floorOnAppLoad)
      ? modelRules.floorOnAppLoad
      : clientFloorOnAppLoad;

    // <----- Just for Arbor ----->
    let communityDirectory = null;
    if (showCommunityDropdownList) {
      const communityList = await Api.fetchListOfCommunities(); // Get List of All Communities
      const arrayOfCommunityCalls = await Api.generateArrayOfCommunityCalls(communityDropdownList); // Generate an array of API urls
      const arrayOfCallResponses = await Api.fetchArrayOfCommunityModels(arrayOfCommunityCalls); // Generate array of all response data
      const commsWithModelRID = KovaData.findCommunitiesWithModelRID(arrayOfCallResponses, modelRID);
      const filteredUrls = commsWithModelRID.map(data => data.config.url); // Generate array of urls for parsing commRID
      const filteredCommRids = filteredUrls.map(url => KovaData.parseCommRidFromUrl(url)); // Generate array of commRIDs
      communityDirectory = KovaData.mapCommRidToCommunityName(filteredCommRids, communityList);
      setCommunityDirectory(communityDirectory);

      // Set default community for Arbor to 278 - if 278 does not exist, use first in array
      const baseCommRid = communityDirectory.find(comm => comm.rid === communityDropdownList[0]) || communityDirectory[0] || { rid: 219 };
      setCommunityDetails(baseCommRid.rid);
      setDropdownCommRID(baseCommRid.rid);
    }
    // <----- END JUST FOR ARBOR ----->

    if (touchscreenEnabled) {
      this.initSocket(roomId, GlobalConfig.get(GlobalConfig.Key.SOCKET_URL));
      setCommunityDetails(communityRID);
    } else if (!showCommunityDropdownList && communityRID > 0) {
      setCommunityDetails(communityRID);
    }

    // REDUX ACTION DISPATCHES //
    setModelRID(modelRID);
    setCommunityRID(communityRID);
    setCommunityModelList(communityRID);
    setShowKovaStage(showKovaStage);
    setModelRules(modelRules);
    setModelDetails(communityRID, modelRID);
    setSelectedFloor(selectedFloor);

    // TODO: Need to handle this properly - hacky fix just to
    // get it to load consistently
    setTimeout(() => {
      setPriceList(communityRID, modelRID);
    }, 1000);

    this.initializeModelInState({
      communityRID,
      modelRID,
      selectedFloor,
      roomId,
      quickMove,
      worksheet
    });

    this.setState({ modelRID, communityRID });
  }

  handleClearSelections = () => {
    const {
      communityRID,
      modelRID,
      quickMoveIn,
      roomId
    } = this.state;

    const {
      selectedFloor
    } = this.props;

    if (quickMoveIn && quickMoveIn !== 0) {
      return null;
    }

    return this.initializeModelInState({
      communityRID,
      modelRID,
      selectedFloor,
      roomId
    });
  };

  initializeModelInState = async ({
    communityRID,
    modelRID,
    selectedFloor,
    roomId,
    quickMove,
    worksheet
  }) => {
    const {
      setConfigData,
      optionTotal,
      touchscreenEnabled,
      showKovaStage
    } = this.props;

    const forQMI = quickMove;
    const forWkst = worksheet;

    this.showLoadingIndicator(true);

    // Fetch initial model data from API
    let configData = null;

    if (forQMI) {
      configData = await this.applySelectedOptions(communityRID, modelRID, selectedFloor, forQMI, 'QMI');
    } else if (forWkst) {
      configData = await this.applySelectedOptions(communityRID, modelRID, selectedFloor, forWkst, 'WKST');
    } else {
      configData = await Api.fetchStandardModelOpts(
        communityRID,
        modelRID,
        selectedFloor
      ).catch(() => false);
    }

    let filteredOptImgs;
    // <----- Just for OAKWOOD ----->
    if (touchscreenEnabled) {
      const event = {
        name: 'option data',
        roomId,
        data: {
          selectedOptions: configData.SelectedOptions,
          optionTotal
        }
      };

      this.socket.emit('option data', event);
    }
    // <----- END JUST FOR OAKWOOD ----->

    if (showKovaStage) {
      // START ----- NEW KOVA IFP PROCESS
      // Create array of floor IDs
      const modelFloorList = Object.keys(configData.Floors);

      // Fetch list of floors associated with Model RID. This will return all floors for a Model
      // regardless of community RID. (i.e. 19344 will return Chaise floors for GVRE, TRR, etc)
      const odataFloors = await Api.fetchModelFloorRIDs(modelRID);

      // Find the correct floor RIDs for community by filtering against modelFloorList
      const modelFloorRIDs = odataFloors.filter(floor =>
        modelFloorList.includes(floor.ModelFlrID));

      // Arbor floors come in out of order, so they need to be re-ordered to match modelFloorList
      const reorderedFloors = [];
      modelFloorList.forEach((floor) => {
        const foundFloor = modelFloorRIDs.find(floorObj => floorObj.ModelFlrID === floor);
        reorderedFloors.push(foundFloor);
      });

      // Fetch 2D array of floor image objects
      const optImgs = await Promise.all(
        reorderedFloors.map(floor => Api.fetchModelOptCfgImgs(floor.ModelFlrRID))
      );

      filteredOptImgs = optImgs.map(imgsByFloor =>
        imgsByFloor.filter(
          img =>
            img.Type === 'BaseLabel'
            || img.Type === 'OptionLabel'
            || img.Type === 'Option'
            || img.Type === 'Base'
        ));
    }
    // END ----- NEW KOVA IFP PROCESS

    // REDUX ACTION DISPATCHES //
    setConfigData(configData);

    this.setState({
      updatingData: false,
      filteredOptImgs,
      quickMoveIn: quickMove,
    });
  };

  /**
   * This fires if there is ether a SlsOrdRID or OptionsSlsRID  given as a query param
   * NOTE: if you are looking to affect change to a selected option after load (so not
   * applying seleted options from a worksheet or a selected optionRID) you need to look
   * elsewhere.
   *
   * if SlsOrdRID is passed 'fetchOptionsSlsRID' will fire from Api.js, grabbing a quick movein
   * ---- I've noticed that occasionally some options will have selections that are not in the
   * ---- pricing information, I have written the code to simply ignore this as pricing and option
   * ---- selection is 'frozen'
   *
   * if slsWshRID is passed 'grabAWorksheet' will fire from Api.js, grabbing a standard worksheet
   * ---- because of the note above, If somehow an option is present that DOES NOT APPEAR AS AN OPTION
   * ---- IN OUR PRICING INFORMATION, IT WILL BE IGNORED!!!! there is no way to change this unless we are given
   * ---- and updated list.
   *
   * - Author William Mitchell, Sept, 14th,
   */

  applySelectedOptions = async (communityRID, modelRID, selectedFloor, RID, origin) => {
    // const { communityRID, modelRID, selectedFloor } = this.state;

    // 124760 - will work as a wish.
    // 26092 - will work with fetchOptionsSlsRID, model 2561, RID 224

    const selectedOptionArray = origin === 'QMI'
      ? await Api.fetchOptionsSlsRID(RID)
      : await Api.grabAWorksheet(RID);

    const configData = await Api.fetchStandardModelOpts(
      communityRID,
      modelRID,
      selectedFloor
    );

    const configDataKeys = Object.keys(configData.SelectedOptions);
    const matchedOptions = [];

    selectedOptionArray.forEach((option, idx) => {
      if (
        configDataKeys.includes(option.OptSelID)
        || configDataKeys.includes(option.OptValID)
      ) {
        if (configData.Options[option.OptSelID]) {
          option.optionType = configData.Options[option.OptSelID].Type;
        }
        matchedOptions.push(option);
      } else {
        const choiceType = 'SingleChoice';
        option.choiceType = choiceType;
        matchedOptions.push(option);
      }
    });

    const newSelectedOptions = configData;

    matchedOptions.forEach((option) => {
      if (option.optionType) {
        const {
          OptSelID: categoryId,
          OptValID: optionId,
          optionType: choiceType
        } = option;

        const updatedSelectedOptions = KovaData.updateSelectedOptions(
          newSelectedOptions.SelectedOptions,
          categoryId,
          optionId,
          choiceType
        );
        newSelectedOptions.SelectedOptions = updatedSelectedOptions;
      } else if (option.choiceType === 'SingleChoice') {
        const {
          OptSelID: categoryId,
          OptValID: optionId,
          choiceType
        } = option;

        const updatedSelectedOptions = KovaData.updateSelectedOptions(
          newSelectedOptions.SelectedOptions,
          categoryId,
          optionId,
          choiceType
        );
        newSelectedOptions.SelectedOptions = updatedSelectedOptions;
      } else {
        const categoryId = option.OptSelID;
        const optionId = option.OptValID;
        const updatedSelectedOptions = KovaData.updateHiddenOptions(
          newSelectedOptions.SelectedOptions,
          categoryId,
          optionId
        );
        newSelectedOptions.SelectedOptions = updatedSelectedOptions;
      }
    });

    const newConfigData = await Api.postConfigDataUpdate(
      communityRID,
      modelRID,
      selectedFloor,
      'True',
      newSelectedOptions.SelectedOptions
    ).catch(() => false);

    return newConfigData;
  };

  initSocket = (roomId) => {
    const { toggleSideMenu } = this.props;

    if (roomId) {
      const config = {
        listeners: [
          {
            name: 'print brochure',
            eventHandler: data => this.printFloor(data)
          },
          {
            name: 'toggle menu',
            eventHandler: () => toggleSideMenu()
          },
          {
            name: 'save worksheet',
            eventHandler: data => this.handleSaveWorksheet(data)
          }
        ]
      };

      const socket = new Socket(config);
      socket.init(roomId, GlobalConfig.get(GlobalConfig.Key.SOCKET_URL));
      socket.listen();

      this.setState({ roomId });

      this.socket = socket;
    }
  };

  printFloor = (data) => {
    const { toggleTriggerPrint } = this.props;
    toggleTriggerPrint(null);
  };

  showLoadingIndicator = (updatingData = true) => {
    this.setState({
      updatingData
    });
  };

  handleDownloadClose = (e) => {
    setDownloadURL(null);
  }

  /**
   * Click must pass in a custom data with specific key/value pairs
   * depending on user click type (i.e. changing floors or toggling options).
   *
   * Data parameter is an object with the following possible properties:
   *  - 'clickType':'optionChange' (static value)
   *  - 'optionId': specific option ID
   *  - 'categoryId': category for MultiChoice (i.e. 'ELEVATION')
   *                  or same as optionId for SingleChoice
   *  - 'choiceType': 'SingleChoice' or 'MultiChoice'
   *
   * EXAMPLE: For single choice options, the optionId & choiceId will be the same.
   * For multi choice options, the categoryId is the category (i.e. 'ELEVATION'
   * and the optionId will be the specific selection (i.e. 'elev_a||'))
   *
   * The function return value should be stored in state as configData
   */
  handleOptionItemClick = async (event, data) => {
    event.preventDefault();

    const {
      communityRID,
      modelRID,
      roomId,
      quickMoveIn
    } = this.state;

    const {
      setConfigData,
      optionTotal,
      configData,
      selectedFloor,
      touchscreenEnabled
    } = this.props;

    const {
      clickType,
      option,
      categoryId,
      choiceType
    } = data;

    if (!quickMoveIn) {
      this.showLoadingIndicator();

      let newConfigData = null;

      if (clickType === 'optionChange') {
        const updatedSelectedOptions = KovaData.updateSelectedOptions(
          configData.SelectedOptions,
          categoryId,
          option,
          choiceType
        );

        newConfigData = await Api.postConfigDataUpdate(
          communityRID,
          modelRID,
          selectedFloor,
          'True',
          updatedSelectedOptions
        ).catch(() => false);

        // REDUX ACTION DISPATCHES //
        setConfigData(newConfigData);

        this.setState({
          updatingData: false,
          configData: newConfigData,
          optionTotal
        });

        //   <----- Just for OAKWOOD ----->
        if (touchscreenEnabled) {
          const optionEvent = {
            name: 'option data',
            roomId,
            data: {
              selectedOptions: newConfigData.SelectedOptions,
              optionTotal
            }
          };

          this.socket.emit('option data', optionEvent);
        }
        // <----- END JUST FOR OAKWOOD ----->
      } else {
        throw new Error(
          'Invalid clickType passed to handleOptionItemClick handler'
        );
      }

      return newConfigData;
    }
  };

  fetchNewFloorData = async (newSelectedFloor) => {
    const { communityRID, modelRID } = this.state;

    const {
      configData,
      setConfigData,
      setSelectedFloor,
      toggleResetView,
    } = this.props;

    const newFloorConfig = await Api.postConfigDataUpdate(
      communityRID,
      modelRID,
      newSelectedFloor,
      'True',
      configData.SelectedOptions
    ).catch(() => false);

    setConfigData(newFloorConfig);
    setSelectedFloor(newSelectedFloor);
    toggleResetView();
  }

  /**
   * Re-usable function that can either change floors based on a user
   * toggling floors via the ViewToggle component and direction parameter.
   *
   * Can also accept a specific floor index (floorToLoad parameter) and update
   * app to load specific floor.
   */
  handleFloorToggle = async ({ direction, floorToLoad }) => {
    this.showLoadingIndicator();

    // Handles user clicks in the ViewToggle Component
    if (direction) {
      const {
        selectedFloor,
        availFloors
      } = this.props;

      const newFloor = handleToggleView(availFloors, selectedFloor, direction);
      await this.fetchNewFloorData(newFloor);
    }

    // Handles the passing in of a specific floor index
    if (floorToLoad || floorToLoad === 0) {
      await this.fetchNewFloorData(floorToLoad);
    }

    this.setState({
      updatingData: false,
    });
  };

  handleSaveWorksheet = async (data) => {
    const {
      communityRID,
      modelRID,
    } = this.state;

    const {
      priceList,
      configData: { SelectedOptions },
    } = this.props;

    try {
      this.setState({ savingStatus: 'saving your options...' });
      await SaveWorksheet.saveNewWorksheet(
        communityRID,
        data,
        modelRID,
        priceList,
        SelectedOptions
      );
      this.setState({ savingStatus: '' });
    } catch (err) {
      console.error(err);
      alert(err);
    }
  };

  formatSelectedOption(option) {
    return option.replace(/\|\|/g, '').toLowerCase();
  }

  render() {
    const {
      isActiveAdult,
      optionTotal,
      roomId,
      savingStatus,
      updatingData,
      elevationOptions,
      filteredOptImgs,
      quickMoveIn,
    } = this.state;

    const {
      clientFloorOnAppLoad,
      triggerPrint,
      toggleTriggerPrint, // needs to be kept for print button testing below
      configData,
      touchscreenEnabled,
      modelDetails,
      useKovaPDFDownloader,
      colorSelectorEnabled,
      downloadURL,
      setDownloadURL,
    } = this.props;

    return (
      <>
        {
          <div className={styles.app}>
            {/* <button
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                height: '50px',
                width: '100px',
                cursor: 'pointer',
                zIndex: 100000
              }}
              onClick={toggleTriggerPrint}>
                Print test
            </button> */}
            <div
              style={{
                position: 'fixed',
                bottom: '5px',
                right: '5px',
                color: '#aaa'
              }}
            >
              {version}
            </div>
            {(configData && !touchscreenEnabled && colorSelectorEnabled && (
              <ColorSelector>
                <Dashboard
                  clientFloorOnAppLoad={clientFloorOnAppLoad}
                  handleOptionItemClick={this.handleOptionItemClick}
                  handleClearSelections={this.handleClearSelections}
                  handleFloorToggle={this.handleFloorToggle}
                  filteredOptImgs={filteredOptImgs}
                />
              </ColorSelector>
            ))}
            {(configData && touchscreenEnabled && !colorSelectorEnabled && modelDetails && (
              <Dashboard
                handleOptionItemClick={this.handleOptionItemClick}
                handleClearSelections={this.handleClearSelections}
                handleFloorToggle={this.handleFloorToggle}
                isActiveAdult={isActiveAdult}
                filteredOptImgs={filteredOptImgs}
                modelDetails={modelDetails}
                quickMoveIn={quickMoveIn}
              />
            ))}
            {triggerPrint && useKovaPDFDownloader && (
              <KovaPDFDownloader
                optionTotal={optionTotal}
                roomId={roomId}
                socket={this.socket}
                elevationOptions={elevationOptions}
                filteredOptImgs={filteredOptImgs}
              />
            )}
            {triggerPrint && !useKovaPDFDownloader && (
              <PDFDownloader
                optionTotal={optionTotal}
                roomId={roomId}
                socket={this.socket}
                elevationOptions={elevationOptions}
              />
            )}
            {savingStatus && (
              <div className={styles.savingIndicator}>
                <LoadingIndicator message={savingStatus} />
              </div>
            )}
            {updatingData && (
              <div className={styles.generalIndicator}>
                <LoadingIndicator message={savingStatus} />
              </div>
            )}

            {downloadURL && (
              <div className={styles.generalIndicator}>
                <DownloadDialog downloadURL={downloadURL} clickHandler={() => setDownloadURL(null)} />
              </div>
            )}
          </div>
        }
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  modelDetails: selectModelDetails,
  triggerPrint: selectTriggerPrint,
  availFloors: selectAvailFloors,
  model: selectModel,
  configData: selectConfigData,
  optionTotal: selectOptionTotal,
  priceList: selectPriceList,
  selectedFloor: selectFloor,
  modelRules: selectModelRules,
  showElevation: selectShowElevation,
  downloadURL: selectDownloadURL
});

const mapDispatchToProps = dispatch => ({
  toggleTriggerPrint: () => dispatch(toggleTriggerPrint()),
  toggleSideMenu: () => dispatch(toggleSideMenu()),
  toggleResetView: () => dispatch(toggleResetView()),
  setModelRID: modelRID => dispatch(setModelRID(modelRID)),
  setConfigData: configData => dispatch(setConfigData(configData)),
  setModelRules: rules => dispatch(setModelRules(rules)),
  setSelectedFloor: floor => dispatch(setSelectedFloor(floor)),
  setCommunityRID: communityRID => dispatch(setCommunityRID(communityRID)),
  setCommunityDirectory: directory => dispatch(setCommunityDirectory(directory)),
  setCommunityDetails: details => dispatch(setCommunityDetails(details)),
  setCommunityModelList: communityRID => dispatch(setCommunityModelList(communityRID)),
  setShowKovaStage: showKovaStage => dispatch(setShowKovaStage(showKovaStage)),
  setDropdownCommRID: communityRID => dispatch(setDropdownCommRID(communityRID)),
  setModelDetails: (communityRID, modelRID) => dispatch(setModelDetails(communityRID, modelRID)),
  setPriceList: (communityRID, modelRID) => dispatch(setPriceList(communityRID, modelRID)),
  setDownloadURL: url => dispatch(setDownloadURL(url)),
});

App.defaultProps = {
  selectedFloor: -1,
  availFloors: [],
  priceList: {},
  configData: {},
  triggerPrint: false,
  downloadURL: null,
  optionTotal: 0,
  showCommunityDropdownList: false,
  clientFloorOnAppLoad: null
};

App.propTypes = {
  selectedFloor: PropTypes.number,
  availFloors: PropTypes.array,
  priceList: PropTypes.object,
  configData: PropTypes.object,
  optionTotal: PropTypes.number,
  triggerPrint: PropTypes.bool,
  downloadURL: PropTypes.string,
  showCommunityDropdownList: PropTypes.bool,
  clientFloorOnAppLoad: PropTypes.number,
  touchscreenEnabled: PropTypes.bool.isRequired,
  useKovaPDFDownloader: PropTypes.bool.isRequired,
  defaultCommunityRID: PropTypes.number.isRequired,
  defaultModelRID: PropTypes.number.isRequired,
  setModelRID: PropTypes.func.isRequired,
  setCommunityRID: PropTypes.func.isRequired,
  setCommunityDetails: PropTypes.func.isRequired,
  setCommunityModelList: PropTypes.func.isRequired,
  setShowKovaStage: PropTypes.func.isRequired,
  setModelRules: PropTypes.func.isRequired,
  setSelectedFloor: PropTypes.func.isRequired,
  setModelDetails: PropTypes.func.isRequired,
  setCommunityDirectory: PropTypes.func.isRequired,
  setPriceList: PropTypes.func.isRequired,
  setDropdownCommRID: PropTypes.func.isRequired,
  toggleSideMenu: PropTypes.func.isRequired,
  toggleResetView: PropTypes.func.isRequired,
  toggleTriggerPrint: PropTypes.func.isRequired,
  setConfigData: PropTypes.func.isRequired,
  showKovaStage: PropTypes.bool.isRequired,
  loadModelRules: PropTypes.bool.isRequired,
  colorSelectorEnabled: PropTypes.bool.isRequired,
  communityDropdownList: PropTypes.array.isRequired,
  setDownloadURL: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
