import axios from 'axios';
import GlobalConfig from '../GlobalConfig';

export default class Api {
  // This API call will retrieve the model with all standard options selected
  static fetchStandardModelOpts = async (communityRID, modelRID, selectedFloor) => {
    const apiRoot = GlobalConfig.get(GlobalConfig.Key.KOVA_API_ROOT);
    const url = `CommunityModels/All?CommunityRID=${communityRID}&ModelRID=${modelRID}&floorIndex=${selectedFloor}`;
    // const url = `v4/CommunityModels/All?CommunityRID=${communityRID}&ModelRID=${modelRID}&floorIndex=${selectedFloor}&IncludeDesignOptions=False`;

    const response = await axios.get(`${apiRoot}${url}`)
      .catch(() => false);

    return response.data.Plan;
  }

  static fetchCommunityDetails = async (communityRID) => {
    const apiRoot = GlobalConfig.get(GlobalConfig.Key.KOVA_API_ROOT);
    const apiKey = GlobalConfig.get(GlobalConfig.Key.KOVA_PUBLIC_API_KEY) || null;
    const url = `Communities/GetDetails/${communityRID}?auth=${apiKey}`;

    const response = await axios.get(`${apiRoot}${url}`)
      .catch(() => false);

    return response.data;
  }

  static fetchCommunityModels = async (communityRID) => {
    const apiRoot = GlobalConfig.get(GlobalConfig.Key.KOVA_API_ROOT);
    const apiKey = GlobalConfig.get(GlobalConfig.Key.KOVA_PUBLIC_API_KEY) || null;
    const url = `Communities/GetModels/${communityRID}?auth=${apiKey}`;

    const response = await axios.get(`${apiRoot}${url}`)
      .catch(() => false);

    return response.data;
  }

  static fetchListOfCommunities = async () => {
    const apiRoot = GlobalConfig.get(GlobalConfig.Key.KOVA_API_ROOT);
    const apiKey = GlobalConfig.get(GlobalConfig.Key.KOVA_PUBLIC_API_KEY) || null;
    const url = `Communities/GetList?auth=${apiKey}`;

    const response = await axios.get(`${apiRoot}${url}`)
      .catch(() => false);

    return response.data;
  }

  static generateArrayOfCommunityCalls = (arrayOfRIDs) => {
    const apiRoot = GlobalConfig.get(GlobalConfig.Key.KOVA_API_ROOT);
    const apiKey = GlobalConfig.get(GlobalConfig.Key.KOVA_PUBLIC_API_KEY) || null;
    const url = 'Communities/GetModels/';

    return arrayOfRIDs.map(rid => `${apiRoot}${url}${rid}?auth=${apiKey}`);
  };

  static fetchArrayOfCommunityModels = async (arrayOfCallUrls) => {
    const promiseArray = arrayOfCallUrls
      .map(callUrl => Api.fetchCommunityModelsByUrl(callUrl));

    // console.log('promiseArray', promiseArray);

    return axios
      .all(promiseArray)
      .then(responses => responses);
  }

  static fetchCommunityModelsByUrl = async (url) => {
    const response = await axios.get(url)
      .catch(() => false);

    return response;
  }

  /**
   * This API call can be used when a user changes floors or updates and option
   * depending on params passed in. (Note: all params are required)
   *  - Updated floorIndex will change floors and return floor specific options
   *  - Updated selectedOptions to handle changed options
   */
  static postConfigDataUpdate = async (
    communityRID,
    modelRID,
    floorIndex,
    designOpts,
    selectedOptions
  ) => {
    const apiRoot = GlobalConfig.get(GlobalConfig.Key.KOVA_API_ROOT);
    const apiKey = GlobalConfig.get(GlobalConfig.Key.KOVA_PUBLIC_API_KEY) || null;
    const url = `${apiRoot}CommunityModels/StateChanged?auth=${apiKey}`;

    const config = {
      headers: {
        Authorization: `Bearer ${apiKey}`,
        'Content-Type': 'application/json'
      }
    };

    const response = await axios.post(url,
      {
        CommunityRID: communityRID,
        ModelRID: modelRID,
        FloorIndex: floorIndex,
        IncludeDesignOptions: designOpts,
        SelectedOptions: selectedOptions
      },
      config)
      .catch(() => false);

    if (!response.data) {
      alert('Failed to load plan data. Please refresh the page!');
    }

    return response.data.Plan;
  }

  static fetchReferenceWorksheet = async (modelRID) => {
    const url = `${GlobalConfig.get(GlobalConfig.Key.SOCKET_URL)}api/RefWorksheet?modelRID=${modelRID}&kovaEnv=prod`;
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const response = await axios.get(url, config);

    return response.data;
  }

  static fetchCustomerContacts = async (customerRID) => {
    const url = `${GlobalConfig.get(GlobalConfig.Key.SOCKET_URL)}api/CustomerContacts?customerRID=${customerRID}&kovaEnv=prod`;
    const response = await axios.get(url);

    return response.data;
  }

  static saveCustomerWorksheet = async (worksheet) => {
    const url = `${GlobalConfig.get(GlobalConfig.Key.SOCKET_URL)}api/CustomerWorksheet?kovaEnv=prod`;
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const response = await axios.post(url, worksheet, config);
    return response;
  }

  static saveCustomerWorksheetOpt = async (opt) => {
    const url = `${GlobalConfig.get(GlobalConfig.Key.SOCKET_URL)}api/CustomerWorksheetOpt?kovaEnv=prod`;
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const response = await axios.post(url, opt, config);
    return response;
  }

  static fetchCustomerWorksheets = async (customerRID) => {
    const url = `${GlobalConfig.get(GlobalConfig.Key.SOCKET_URL)}api/CustomerWorksheet?customerRID=${customerRID}&kovaEnv=prod`;
    const response = await axios.get(url);

    return response.data;
  }

  static fetchRefWorksheetOpts = async (slsWshRID) => {
    const url = `${GlobalConfig.get(GlobalConfig.Key.SOCKET_URL)}api/RefWorksheetOpts?slsWshRID=${slsWshRID}&kovaEnv=prod`;
    const response = await axios.get(url);

    return response.data;
  }

  static fetchOptVal = async (optValId) => {
    const url = `${GlobalConfig.get(GlobalConfig.Key.SOCKET_URL)}api/OptVal?optValId=${optValId}&kovaEnv=prod`;
    const response = await axios.get(url);

    return response.data;
  }

  static fetchModelOptVal = async (modelRID, optValRID) => {
    const url = `${GlobalConfig.get(GlobalConfig.Key.SOCKET_URL)}api/ModelOptVal?modelRID=${modelRID}&optValRID=${optValRID}&kovaEnv=prod`;
    const response = await axios.get(url);

    return response.data;
  }

  // FOR NEW KOVA API PROCESS

  static fetchModelFloorRIDs = async (modelRID) => {
    const url = `${GlobalConfig.get(GlobalConfig.Key.VSC_API_ROOT)}ModelFlrs?modelRID=${modelRID}`;
    const response = await axios.get(url);

    return response.data;
  }

  static fetchModelOptCfgImgs = async (modelFloorRID) => {
    const url = `${GlobalConfig.get(GlobalConfig.Key.VSC_API_ROOT)}OptCfgImgs?modelFloorRID=${modelFloorRID}`;
    const response = await axios.get(url);

    return response.data;
  }

  static grabAWorksheet = async (slsWshRID) => {
    const url = `${GlobalConfig.get(GlobalConfig.Key.SOCKET_URL)}api/CustomerWorksheetOpt?slsWshRID=${slsWshRID}&kovaEnv=prod`;
    const response = await axios.get(url);
    return response.data;
  }
  
  static fetchOptionsSlsRID = async (SlsOrdRID) => {
    const url = `${GlobalConfig.get(GlobalConfig.Key.SOCKET_URL)}api/SlsOrdOpts?slsOrdRID=${SlsOrdRID}&kovaEnv=prod`;
    const response = await axios.get(url);

    return response.data; 
  }
}
