import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import ViewToggleButton from './ViewToggleButton/ViewToggleButton';
import { ClientSettingsContext } from '../../context/ClientSettingsContext';
import leftArrow from '../../img/left_arrow.png';
import leftArrowB from '../../img/left_arrow_b.png';
import rightArrow from '../../img/right_arrow.png';
import rightArrowB from '../../img/right_arrow_b.png';

import styles from './ViewToggle.module.css';

import { selectFloor, selectAvailFloors } from '../../redux/model/model.selectors';

const ViewToggle = ({
  isActiveAdult,
  selectedFloor,
  availFloors,
  handleFloorToggle
}) => {
  const ClientSettings = useContext(ClientSettingsContext);
  const { theme, touchscreenEnabled } = ClientSettings;
  const currFloor = availFloors.find(floor => floor.Index === selectedFloor);

  return (
    <div className={styles.viewToggle}>
      <ViewToggleButton
        handleFloorToggle={handleFloorToggle}
        direction="left"
        arrowImg={isActiveAdult ? leftArrowB : leftArrow}
        touchscreenEnabled={touchscreenEnabled}
      />
      <div
        className={styles.viewName}
        style={
            {
              textTransform: theme.viewNameTextTransform,
              fontWeight: theme.viewNameFontWeight,
              // color: theme.primaryColor
            }
          }
      >
        {`${(currFloor && currFloor.Description) || 'Not Found'}`}
      </div>
      <ViewToggleButton
        handleFloorToggle={handleFloorToggle}
        direction="right"
        arrowImg={isActiveAdult ? rightArrowB : rightArrow}
        touchscreenEnabled={touchscreenEnabled}
      />
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  selectedFloor: selectFloor,
  availFloors: selectAvailFloors
});

ViewToggle.propTypes = {
  handleFloorToggle: PropTypes.func.isRequired,
  selectedFloor: PropTypes.number.isRequired,
  availFloors: PropTypes.array.isRequired,
};

export default connect(mapStateToProps)(ViewToggle);
