/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
/* eslint-disable no-alert */
/* eslint-disable brace-style */

// there is not a "replaceAll" function in javascript that works with all browsers(?) hacking this in
function replaceAll(string, search, replace) {
  return string.split(search).join(replace);
}

// This function solves the option string and returns a true/false based on what (selected) optinos are passed.
// / a "debug" parameter is passed, when true will throw a bunch of alerts up to help validated what is going on as a option string is evaluated.
// NOTE: THE ASSUMPTION IS THE OPTION STRING IS IN "STANDARD BLACKPOINT" FORMAT.    It is somwhat particular where blank spaces are located.
function SolveOptionString(selectedOptions, optstring, debug) {
  let isSolved = false; // default do not show.

  let convertedOptString = optstring.toLowerCase();

  convertedOptString.trim();
  if (convertedOptString === '') // if no option string then return true immediately
  {
    isSolved = true;
  } else {
    // replace basics "special characters"
    convertedOptString = replaceAll(convertedOptString, ' and ', ' && ');
    convertedOptString = replaceAll(convertedOptString, ' or ', ' || ');
    convertedOptString = replaceAll(convertedOptString, 'not ', ' ! ');
    convertedOptString = replaceAll(convertedOptString, ' always ', ' true ');
    convertedOptString = replaceAll(convertedOptString, ' never ', ' false ');
    convertedOptString = replaceAll(convertedOptString, '(', '( ');
    convertedOptString = replaceAll(convertedOptString, ')', ' )');
    convertedOptString = ` ${convertedOptString} `;

    if (debug) alert(`REPLACE SPECIAL: ${optstring}\n\n${convertedOptString}  =  `);

    // sort string by length so it does the longer options name first
    // you do not want a smaller option string replaced in a larger string (ie "bsmt" replaced in "bsmt_full" to give you something like "true_full")
    selectedOptions = selectedOptions.slice().sort((a, b) => b.length - a.length);

    /**
     * The code block below was added for Arbor to handle how their image Conditional
     * properties use options an selection joined by a colon (i.e. SOME_OPTION:selected-opt)
     */
    const splitOptionString = convertedOptString.split(' ');
    const removedColonStrings = splitOptionString.reduce((accum, currVal) => {
      if (currVal.length === 0) {
        accum += ' ';
      } else if (currVal.includes(':')) {
        accum += currVal.split(':')[1];
      } else {
        accum += ` ${currVal} `;
      }
      return accum;
    }, '');

    convertedOptString = removedColonStrings;

    // replace each selected option in the string with true, ignore case
    for (let i = 0; i < selectedOptions.length; i += 1) {
      convertedOptString = replaceAll(
        convertedOptString,
        ` ${selectedOptions[i].toLowerCase()} `,
        ' true '
      );
    }

    if (debug) alert(`REPLACE SELECTECTED OPTS:\n ${optstring}\n\n${convertedOptString}  =  `);

    // find all other option strings and replace with false
    // (ignore "true,false, &&,||,(,)" )
    const optstringPieces = convertedOptString.trim().split(' ');
    for (let i = 0; i < optstringPieces.length; i += 1) {
      let subCode = optstringPieces[i];
      // clean out any special characters or codes.
      subCode = replaceAll(subCode, '(', '');
      subCode = replaceAll(subCode, ')', '');
      subCode = replaceAll(subCode, '!', '');

      if ((subCode.trim() !== '')
        && (subCode !== 'true')
        && (subCode !== 'false')
        && (subCode !== '&&')
        && (subCode !== '||')
      ) {
        if (debug) alert(`swapping:\n ${subCode} with false in \n\n${convertedOptString}`);

        convertedOptString = convertedOptString.replace(subCode, 'false');
      }
    }
    convertedOptString.trim();
    if (debug) alert(`REPLACE FALSE OPTS:\n ${optstring}\n\n${convertedOptString}`);

    /**
     * -- this is really the key --
     * it take string in a logic format and evaluates the logic to return true/false.
     * (native javascript function.)
     *  */
    // eslint-disable-next-line no-eval
    isSolved = eval(convertedOptString.toString());
  }

  if (debug) alert(`FINAL SOLVE: \n${optstring}\n\n${convertedOptString}  =  ${isSolved}`);
  return isSolved;
}

// return the bottom offset of the image, for "base" image just use global offset
function calcBottomOffset(optImage, globalBottomOffset, kovaImageScale) {
  let offset = 0;
  // if (optImage.LOCX === 0 && optImage.LOCY === 0 && (optImage.Type !== 'BaseLabel' && optImage.Type !== 'OptionLabel')) {
  //   offset = globalBottomOffset;
  // } else {
  //   offset = globalBottomOffset + optImage.Y - optImage.LOCY;
  // }
  if (optImage.Type === 'Base') {
    offset = globalBottomOffset;
  } else {
    offset = globalBottomOffset + optImage.Y - optImage.LOCY;
  }
  return `${offset * kovaImageScale}`;
}

// return the left offset of the image, for "base" image just use global offset
function calcLeftOffset(optImage, globalLeftOffset, kovaImageScale) {
  let offset = 0;
  // if (optImage.LOCX === 0 && optImage.LOCY === 0 && (optImage.Type !== 'BaseLabel' && optImage.Type !== 'OptionLabel')) {
  //   offset = globalLeftOffset;
  // } else {
  //   offset = globalLeftOffset + optImage.X - optImage.LOCX;
  // }
  if (optImage.Type === 'Base') {
    offset = globalLeftOffset;
  } else {
    offset = globalLeftOffset + optImage.X - optImage.LOCX;
  }
  return `${offset * kovaImageScale}`;
}

// / return the "z" position of the image
// / zindex is reverse of area .. largest piece has lowest z.  just put (-) area
// / this was much easier that trying to evaluate\sort them all by area  give an +1, +2, etc
// / since tha MAX range value for this is int.max( ± 2147483647) it should not be a problem (~46,000 x 46,000 pixels)
function calcZIndex(optImage) {
  const maxInt = 2147483647;

  // Places the base image at the lowest possible z-Index
  if (optImage.LOCX === 0 && optImage.LOCY === 0) {
    return ((maxInt - 1) * -1);
  }
  // Fires if base image but LOCX and LOCY vals !== 0 (discrepancy between builders/models)
  if (optImage.Type === 'Base') {
    return ((maxInt - 1));
  }
  return Math.round((optImage.Width * optImage.KX) * (optImage.Height * optImage.KY));
}

// / returns the FULL relative image name, including subdirectory.
// / this also performs the SOLVE, and if the image solve =false, then place a "blank.jpg" (1x1 white pixel)
// / in the spot. (really should be at zindex=-[int.max], behind everything. )
// / this is pretty "hacky" but it works.
function calculateImageName(optImage, imgFolder, selectedOptions) {
  const optString = optImage.Condition;
  const showImage = SolveOptionString(selectedOptions, optString, false);

  if (!showImage) {
    // -- if solve = false then set image name to non-existant "blank.jpg"
    // quick hack.  blank.,jpg should by 1x1 white square and have the min zindex  (-214748364)
    // return "908.2CO/OptCfgImgs/"+"blank.jpg";
    return false;
  }
  return imgFolder + optImage.FileName;
}

function determineLabelVis(optImage, selectedOptions) {
  const optString = optImage.Condition;
  return SolveOptionString(selectedOptions, optString, false)
    ? 'visible'
    : 'hidden';
}

// returns boolean true if boxes overlap
function isOverlapped(labelA, labelB) {
  return (
    labelA.x1 < labelB.x2
    && labelA.x2 > labelB.x1
    && labelA.y1 < labelB.y2
    && labelA.y2 > labelB.y1
  );
}

function setBoxDiagonal(optLabel) {
  const {
    X: x,
    Y: y,
    LOCX: locX,
    LOCY: locY,
    Height: height,
    Width: width,
    HAlign: hAlign,
    VAlign: vAlign,
    FileName: fileName,
    Description: description,
    Angle: angle,
    ObjectRID: objectRID,
    Type: type
  } = optLabel;

  const isRotated = angle % 360 !== 0;

  const output = {
    x1: null,
    y1: null,
    x2: null,
    y2: null,
    area: null,
    fileName,
    description,
    objectRID,
    height,
    width,
    type,
    angle: angle % 360
  };
  const modHeight = height;
  const modWidth = width;

  const initialX = (x - locX);
  const initialY = (y - locY - height);

  output.height = modHeight;
  output.width = modWidth;

  // set X coordinates
  if (hAlign === 'L') {
    output.x1 = initialX;
    // if box is rotated then you need to add height & not width
    output.x2 = !isRotated ? initialX + modWidth : initialX + modHeight;
  }
  if (hAlign === 'C') {
    const topLeftX = initialX - (modWidth * 0.5);
    output.x1 = topLeftX;
    output.x2 = topLeftX + modWidth;
  }

  // set Y coordinates
  if (vAlign === 'T') {
    output.y1 = initialY;
    output.y2 = !isRotated ? initialY + modHeight : initialY + modWidth;
  }
  if (vAlign === 'C') {
    const topLeftY = initialY - (modHeight * 0.5);
    output.y1 = topLeftY;
    output.y2 = topLeftY + modHeight;
  }

  output.area = modHeight * modWidth;

  return output;
}

/*
* For each option label - returns parent option data:
* Example:
*   '603727': {
*     parentOption: 'First_Floor-OPT-3car.jpg',
*     parentObjectRID: 603726,
*     area: 346944
*   }
*/
function matchOptionLabelWithParent(optionLabels, optionImgs) {
  const result = {};

  for (let i = 0; i < optionLabels.length; i += 1) {
    const currOptLabel = optionLabels[i];
    const key = currOptLabel.ObjectRID;
    const parsedLabelFilename = currOptLabel.FileName.split('.');
    const parentOption = `${parsedLabelFilename[0]}.${parsedLabelFilename[1]}`;

    const foundParent = optionImgs
      .find(optImg => optImg.FileName === parentOption);

    result[key] = {
      parentOption,
      parentObjectRID: foundParent.ObjectRID,
      area: foundParent.Height * foundParent.Width
    };
  }
  return result;
}

function generateImgSizeLoc(allimgs) {
  return allimgs.reduce((accum, currVal) => {
    accum[currVal.ObjectRID] = setBoxDiagonal(currVal);
    return accum;
  }, {});
}

/*
* For each option label - find base/other option labels that it overlaps
* Example:
    '603780': {
      overlappedLabels: {
        base: [ 603753 ], (Number)
        options: [ 603781 ]
      }
    },
*/
function findAllOptionLabelOverlaps(optionLabels, allLabels, locationData) {
  const result = {};

  for (let i = 0; i < optionLabels.length; i += 1) {
    const currRID = optionLabels[i].ObjectRID;
    const key = currRID;
    result[key] = {
      overlappedLabels: {
        base: [],
        options: []
      }
    };

    for (let j = 0; j < allLabels.length; j += 1) {
      const currLocData = locationData[String(allLabels[j].ObjectRID)];

      if (currLocData.objectRID !== currRID) {
        const overlapped = isOverlapped(
          locationData[String(currRID)],
          locationData[String(currLocData.objectRID)],
        );

        if (overlapped) {
          currLocData.type === 'BaseLabel'
            ? result[key].overlappedLabels.base.push(currLocData.objectRID)
            : result[key].overlappedLabels.options.push(currLocData.objectRID);
        }
      }
    }
  }
  return result;
}

// Returns an array of just the labels to be displayed based on Selected Options
function createLabelList(labels, selectedOptions) {
  const result = [];

  labels.forEach((optImage) => {
    const optString = optImage.Condition;
    if (SolveOptionString(selectedOptions, optString, false)) {
      result.push(optImage);
    }
  });
  return result;
}

/*
 For each specific option
  -> creates an array storing related option labelRIDs

  Example:
  '603779': {
    fileName: 'First_Floor-OPT-ref.jpg',
    description: 'Laramie (908.3.CS) / First Floor / Options, kit_base2fridge',
    condition: 'kit_base2fridge',
    relatedOptLabels: [ 603780, 603781, 603782, 603783 ]
  },

*/
function createOptionLabelLookup(optImgs, optionLabels) {
  const result = {};

  for (let i = 0; i < optImgs.length; i += 1) {
    const relatedOptLabels = [];
    const key = optImgs[i].ObjectRID;

    result[key] = {
      fileName: optImgs[i].FileName,
      description: optImgs[i].Description,
      condition: optImgs[i].Condition
    };

    for (let j = 0; j < optionLabels.length; j += 1) {
      if (optionLabels[j].FileName.includes(optImgs[i].FileName)) {
        relatedOptLabels.push(optionLabels[j].ObjectRID);
      }
    }

    result[key].relatedOptLabels = relatedOptLabels;
  }
  return result;
}

/*
* Input:
* baseLabels: (Array) - base label Img objects from odata
* optLabelRIDs: (Array) - option label RIDs based on selected options
* optLabelOverlaps: (Object)
*   -> [key] = option label RID
*   -> [data] = overlapped labels object with arrays for overlapped base and other option label RIDs
*/

function removeOverlappedBaseLabels(baseLabels, optLabelRIDs, optLabelOverlaps) {
  let baseLabelRIDs = baseLabels.map(baseLabel => baseLabel.ObjectRID);

  for (let i = 0; i < optLabelRIDs.length; i += 1) {
    const baseLabelsToRemove = optLabelOverlaps[optLabelRIDs[i]].overlappedLabels.base;
    baseLabelRIDs = baseLabelRIDs.filter(
      baseLabelRID => !baseLabelsToRemove.includes(baseLabelRID)
    );
  }

  return baseLabels.filter(label => baseLabelRIDs.includes(label.ObjectRID));
}

/**

 * optLabelRIDs: (Array) - option label RIDs based on selected options
 * optLabelOverlaps: (Object)
 *   -> [key] = option label RID
 *   -> [data] = overlapped labels object with arrays for overlapped base and other option label RIDs
 * parentOptionLookup: contains the area information needed to determine which option should be shown
 * -----
 * if two option labels overlap, only show the label whose PARENT has the smaller area
 * if the parent of each option is the same size, then show both option labels.
 */
function handleOptionLabelOverlaps(optLabelRIDs, optLabelOverlaps, parentOptionLookup) {
  let output = [];

  for (let i = 0; i < optLabelRIDs.length; i += 1) {
    let optLabelConflicts = optLabelOverlaps[optLabelRIDs[i]].overlappedLabels.options;
    optLabelConflicts = optLabelConflicts.filter(rid => optLabelRIDs.includes(rid));

    if (optLabelConflicts.length === 0) {
      output.push(optLabelRIDs[i]);
    } else {
      for (let j = 0; j < optLabelConflicts.length; j += 1) {
        const optAParent = parentOptionLookup[optLabelRIDs[i]];
        const optBParent = parentOptionLookup[optLabelConflicts[j]];

        if (optAParent.area < optBParent.area) {
          output.push(optLabelRIDs[i]);
          output = output.filter(rid => rid !== optLabelConflicts[j]);
        } else if (optBParent.area < optAParent.area) {
          output.push(optLabelConflicts[j]);
          output = output.filter(rid => rid !== optLabelRIDs[i]);
        } else {
          output.push(optLabelRIDs[i]);
          output.push(optLabelConflicts[j]);
        }
      }
    }
  }
  return output;
}

export {
  calculateImageName,
  calcZIndex,
  calcLeftOffset,
  calcBottomOffset,
  determineLabelVis,
  createLabelList,
  setBoxDiagonal,
  generateImgSizeLoc,
  matchOptionLabelWithParent,
  findAllOptionLabelOverlaps,
  createOptionLabelLookup,
  removeOverlappedBaseLabels,
  handleOptionLabelOverlaps
};
