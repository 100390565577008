/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import html2canvas from 'html2canvas';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  BlobProvider,
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
  // Needed to rename to avoid conflict with native 'new Image()' constructor
  Image as NewImage
} from '@react-pdf/renderer';

// import logo from '../../img/Oakwood_Stacked_White.png';
import Api from '../../utils/Api/Api';
import KovaFloorplanGraphic from './KovaFloorplanGraphic/KovaFloorplanGraphic';
import KovaData from '../../utils/KovaData/KovaData';
import S3Utils from '../../utils/S3/S3';
import ProgressBar from '../../shared/ProgressBar/ProgressBar';
import { ClientSettingsConsumer } from '../../context/ClientSettingsContext';

import {
  generateImgSizeLoc,
  matchOptionLabelWithParent,
  findAllOptionLabelOverlaps,
  createOptionLabelLookup,
} from '../../utils/FloorplanBuilder/FloorplanBuilder';

import { selectCommunityRID, selectCommunityDetails } from '../../redux/community/community.selectors';
import {
  selectModelRID,
  selectConfigData,
  selectAvailFloors,
  selectBasePrice,
  selectModelDetails,
  selectKeyMap
} from '../../redux/model/model.selectors';
import { setDownloadURL, toggleTriggerPrint } from '../../redux/app/app.actions';

import AvenirLight from '../../fonts/Avenir-Light.ttf';
import AvenirMedium from '../../fonts/Avenir-Roman.ttf';
import GlobalConfig from '../../utils/GlobalConfig';

Font.register({ family: 'Avenir-Light', src: AvenirLight });
Font.register({ family: 'Avenir-Medium', src: AvenirMedium });

const disclaimerText = {
  oakwood: 'Elevations and floor plan contained herein are not to scale and are graphic illustrations for marketing and presentation purposes only. Actual floor plans and all materials may vary prior to or during construction. Some features shown may be optional. Oakwood Homes reserves the right to substitute materials and components of similar quality,and to change features, options, and architectural details without prior notice. Accordingly, neither these materials, nor any communications made or given in connection with these materials, may be deemed to constitute any representation of warranty, contract, or guarantee or may otherwise be relied upon by any person or entity unless conveyed in written form. Plans, Pricing and Features are subject to change without notice. Elevations may show options that are not standard. Square footage values and dimensions are approximate and may vary.',
  arbor: "Prices, plans, and terms are effective on the date of publication and subject to change without notice. Square footage shown is only an estimate and actual square footage will differ. Depictions of homes or other features are artist conceptions. Hardscape, landscape, and other items shown may be decorator suggestions that are not included in the purchase price and availability may vary. Plans to build out this neighborhood as proposed are subject to change without notice. 3D floor plan rendering is for illustrative purposes only and represents a concept of a standard model of this home and may not represent the home that would be available for purchase at the purchase price shown. The rendering may represent the base model of this home without any options or upgrades, or may depict options, upgrades or features that are available at an additional cost. Furniture placement and flooring is artist's conception and is not intended to show specific detailing. Floor plans are the property of Arbor Homes."
};

const PDFStyles = StyleSheet.create({
  page: {
    backgroundColor: '#fff',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '2%, 4%',
    fontFamily: 'Avenir-Medium',
    maxWidth: '800px'
  },
  heading: {
    border: '1pt solid #57585a',
    flexDirection: 'row',
    marginBottom: '2pt',
    maxWidth: '700px',
  },
  headingTextContainer: {
    width: '65%',
    flexDirection: 'column',
    padding: '0%, 2pt',
    textAlign: 'center',
    justifyContent: 'space-around',
    height: '100%'
  },
  headingText1: {
    fontFamily: 'Avenir-Medium',
    fontSize: '18pt',
    maxWidth: '700px',
  },
  headingText2: {
    borderBottom: '1pt solid #000',
    fontFamily: 'Avenir-Light',
    fontSize: '16pt',
    textTransform: 'capitalize',
  },
  headingDetails: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    paddingTop: '1pt'
  },
  modelNameText: {
    fontFamily: 'Avenir-Medium',
    fontSize: '16pt',
    fontWeight: '500',
    textTransform: 'uppercase',
    flex: 1,
  },
  headingDetailsSpec: {
    flexDirection: 'row',
    fontFamily: 'Avenir-Light',
    fontSize: '10pt',
    flex: 1,
  },
  customModelPrice: {
    width: '100%',
    fontSize: '16pt',
    textAlign: 'right',
  },
  selectionHeading: {
    backgroundColor: '#000',
    color: '#fff',
    fontFamily: 'Avenir-Medium',
    fontSize: '18pt',
    textAlign: 'center',
  },
  optionList: {
    flex: '1',
    fontFamily: 'Avenir-Light',
    fontSize: '10pt',
    marginTop: '2pt',
    padding: '0 1%',
  },
  disclaimer: {
    fontFamily: 'Avenir-Light',
    fontSize: '6pt',
    lineHeight: '1.05pt',
    marginBottom: '1pt',
    padding: '0 1%'
  },
  footer: {
    flexDirection: 'row',
    fontFamily: 'Avenir-Light',
    fontSize: '7pt',
    justifyContent: 'space-between'
  }
});

class KovaPDFDownloader extends Component {
  state = {
    floorplanImgs: [],
    graphicsReady: 0,
    status: '',
    allFloorSelOpts: null,
    listOfValidOptions: null,
    // globalLeftOffset: 100, // Globals
    // globalBottomOffset: 0,
    // selectedOpts: null,
    // baseLabels: null,
    // options: null,
    // optionLabels: null,
    // parentOptionLookup: null,
    // optionLabelOverlaps: null,
    // optionLabelLookup: null,
    // locationData: null,
    allFloorImageData: null
  }

  componentDidMount() {
    const allImageData = this.compileImageDataByFloor();
    this.compileAllSelectedOptsByFloor();
    this.setStatus('floorplan-graphics');
    this.appendBaseImages(allImageData);
  }

  componentDidUpdate(prevProps, prevState) {
    const { graphicsReady, status } = this.state;
    const { availFloors } = this.props;

    if (prevState.status === 'floorplan-graphics' && status === 'pdf') {
      this.setFloorplanImg();
    }

    if (prevState.graphicsReady === availFloors.length - 1
      && graphicsReady === availFloors.length) {
      this.setStatus('pdf');
    }
  }

  appendBaseImages = async (allImageData) => {
    const { communityRID, modelRID } = this.props;
    const imgFolder = `https://s3-us-west-1.amazonaws.com/${GlobalConfig.get(GlobalConfig.Key.S3_KOVA_IFP)}/${communityRID}/${modelRID}/OptCfgImgs/`;

    Object.keys(allImageData).forEach((floor) => {
      const baseImg = allImageData[floor].baseImage;
      const baseImgUrl = `${imgFolder}${baseImg.FileName}`;
      const modifiedRID = `_${baseImg.ObjectRID}`;

      const img = new Image();
      img.src = baseImgUrl;
      img.id = modifiedRID;
      document.body.appendChild(img);
    });
  }

  listPages = (theme, showPriceOnPDF, kovaKeyMap) => {
    const {
      availFloors,
      basePrice,
      communityDetails,
      modelDetails,
    } = this.props;


    let setBeds = '';
    if (modelDetails && modelDetails.NumBeds) {
      setBeds = `${modelDetails.NumBeds} Bed | `;
    }
    const setBaths = (modelDetails && `${modelDetails.NumBaths} Bath | `) || '';
    const setSqf = (modelDetails && `${modelDetails.Sqf} Sq Ft`) || '';

    const modelName = modelDetails.MarketingName;


    // Page is a floor object from the config data object
    return availFloors.map((page, i) => (
      <Page key={`${page.ID}-${page.Type}`} size="A4" style={PDFStyles.page}>
        <View style={{ border: 'none' }}>
          <View style={PDFStyles.heading}>
            <View
              style={{
                height: '100%',
                width: '30%',
                backgroundColor: theme.primaryColor,
                flexDirection: 'row',
                justifyContent: 'center'
              }}
            >
              <NewImage
                src={`./logo/${theme.logo}`}
                style={{
                  maxWidth: '95%',
                  maxHeight: '90%',
                }}
              />
            </View>
            <View style={PDFStyles.headingTextContainer}>
              <Text style={PDFStyles.headingText1}>{`${communityDetails.MarketingName || ''}`}</Text>
              <Text style={PDFStyles.headingText2}>{`${communityDetails.City || ''}, ${communityDetails.State || ''}`}</Text>
              <View style={PDFStyles.headingDetails}>
                <Text style={PDFStyles.modelNameText}>
                  {`${modelName}`}
                </Text>
                <Text style={PDFStyles.headingDetailsSpec}>
                  {`${setBeds}${setBaths}${setSqf}`}
                </Text>
              </View>
            </View>
          </View>
          {showPriceOnPDF && (
            <Text style={PDFStyles.customModelPrice}>
              {`Starting at: $${KovaData.formatNumberWithComma(basePrice)}`}
            </Text>
          )}
        </View>
        <NewImage src={this.determineImgSrc(page)} />
        <View>
          <View style={PDFStyles.selectionHeading}>
            <Text style={{ textTransform: 'capitalize' }}>{page.Description}</Text>
          </View>
          <View style={PDFStyles.optionList}>
            <Text>
              {
                (page.Description !== 'Elevation' || GlobalConfig.get(GlobalConfig.Key.CLIENT_NAME) !== 'oakwood')
                  && this.createOptionText(page.Description, page.Index)
              }
            </Text>
          </View>
        </View>
        <View>
          <View style={PDFStyles.disclaimer}>
            <Text>
              {disclaimerText[GlobalConfig.get(GlobalConfig.Key.CLIENT_NAME)]}
            </Text>
          </View>
          <View style={PDFStyles.footer}>
            <Text>{`Page ${i + 1}`}</Text>
            <Text>{new Date().toLocaleDateString()}</Text>
          </View>
        </View>
      </Page>
    ));
  }

  // ///////////////////  PULLED IN FROM KOVA STAGE  /////////////////////////
  /**
   * Generate all the image data required to determine layering as well as hiding
   * or showing specific options or labels. Based on compileImageData in KovaStage
   */
  compileImageDataByFloor = () => {
    const {
      availFloors,
      filteredOptImgs
    } = this.props;
    const allFloorImageData = {};

    availFloors.forEach((floor) => {
      const imgsByFloor = filteredOptImgs[floor.Index];

      const baseImage = imgsByFloor.find(
        img => img.Type === 'Base'
      );

      const baseLabels = imgsByFloor.filter(img => img.Type === 'BaseLabel');
      const options = imgsByFloor.filter(img => img.Type === 'Option');
      const optionLabels = imgsByFloor.filter(img => img.Type === 'OptionLabel');
      const allLabels = baseLabels.concat(optionLabels);
      const allImgs = allLabels.concat(options);
      const locationData = generateImgSizeLoc(allImgs);
      const parentOptionLookup = matchOptionLabelWithParent(optionLabels, options);
      const optionLabelOverlaps = findAllOptionLabelOverlaps(optionLabels, allLabels, locationData);
      const optionLabelLookup = createOptionLabelLookup(options, optionLabels);

      allFloorImageData[floor.Index] = {
        imgsByFloor,
        baseLabels,
        options,
        optionLabels,
        allLabels,
        allImgs,
        locationData,
        parentOptionLookup,
        optionLabelOverlaps,
        optionLabelLookup,
        baseImage
      };
    });

    this.setState({ allFloorImageData });

    return allFloorImageData;
  }

  // /// END ------- PULLED IN FROM KOVA STAGE ----------- //////////


  /**
   * Fetches a configData object from Kova for each floor and generates an
   * allFloorSelOpts obj in state. Key refers to floor index and value
   * is an array of all the selected option IDs
   */
  compileAllSelectedOptsByFloor = async () => {
    const allConfigData = await this.getConfigDataAllFloors();
    this.generateArraySelOptText(allConfigData);
    const allFloorSelOpts = this.generateAllFloorSelectedOpts(allConfigData);

    this.setState({
      allFloorSelOpts,
      allConfigData
    });
  }

  // Async function fetching all the configData objects
  getConfigDataAllFloors = async () => {
    const {
      communityRID,
      modelRID,
      configData,
      availFloors
    } = this.props;

    const output = {};

    // const floors = availFloors.filter(
    //  floor => floor.Description !== 'Elevation'
    // );
    const promises = availFloors.map(floor => Api.postConfigDataUpdate(
      communityRID,
      modelRID,
      floor.Index,
      'False',
      configData.SelectedOptions
    ));
    const results = await Promise.all(promises);
    availFloors.forEach((floor, index) => {
      output[floor.Index] = results[index];
    });

    return output;
  };

  // Compiles array of selected options
  generateAllFloorSelectedOpts = (allConfigData) => {
    const output = {};
    const { communityDetails } = this.props;

    Object.keys(allConfigData).forEach((floor) => {
      const result = this.findAllSelectedOptions(
        allConfigData[floor].Options,
        communityDetails
      );
      output[floor] = result;
    });

    return output;
  };


  generateArraySelOptText = (objOfConfigData) => {
    const listOfValidOptions = {};

    Object.keys(objOfConfigData).forEach((floorIdx) => {
      const selOptsByFloor = KovaData
        .findAllSelectedOptions(objOfConfigData[floorIdx].Options, false)
        .filter(opt => opt !== 'Not Selected' && opt !== '- Not Selected -');

      listOfValidOptions[floorIdx] = selOptsByFloor;
    });

    this.setState({ listOfValidOptions });
    return listOfValidOptions;
  }

  handleButtonClick = () => {
    const { status } = this.state;

    if (!status) {
      this.setStatus('floorplan-graphics');
    }
  }

  createDocument = (theme, showPriceOnPDF, kovaKeyMap) => (
    <Document>
      {this.listPages(theme, showPriceOnPDF, kovaKeyMap)}
    </Document>
  )

  /**
   * Page arg is the floor obj in availFloors
   * TODO - Might be able to delete out completely
   */
  determineImgSrc = (page) => {
    const { floorplanImgs } = this.state;
    return floorplanImgs[page.Index];
  }


  setFloorplanImg = async () => {
    setTimeout(async () => {
      const { availFloors } = this.props;

      const floorplanImgCalls = availFloors.map(
        floor => this.grabFloorplanImg(floor.ID)
      );

      const imgSrc = await Promise.all([...floorplanImgCalls]);

      if (imgSrc) {
        this.setState({ floorplanImgs: [...imgSrc] });
      }
    }, 10000);
  }

  grabFloorplanImg = async (id) => {
    const input = document.getElementById(id);

    let imgData;

    if (input) {
      const canvas = await html2canvas(
        input,
        { useCORS: true, allowTaint: false }
      );

      imgData = canvas.toDataURL('image/png');
    }

    return imgData;
  }

  createOptionText = (description, index) => {
    const { listOfValidOptions } = this.state;
    /*if (description === 'Elevation') {
      return this.createElevationOptionText();
    }*/
    return `Selected Options: ${listOfValidOptions[index].join(', ')}`;
  }

  createElevationOptionText = () => {
    const { elevationOptions } = this.props;

    let result = [];
    if (elevationOptions) {
      result = Object.keys(elevationOptions).reduce((accum, currVal) => {
        if (elevationOptions[currVal]) {
          accum.push(`${currVal.charAt(0).toUpperCase()}${currVal.slice(1)}: ${elevationOptions[currVal].text} `);
        }
        return accum;
      }, []);
    }

    return `Selected Options: ${result.join(', ')}`;
  }

  setStatus = (status) => {
    this.setState({ status });
  }

  listFloorplanGraphics = () => {
    const {
      availFloors,
      communityRID,
      modelRID,
      filteredOptImgs,
    } = this.props;

    const {
      allFloorSelOpts,
      allFloorImageData
    } = this.state;


    return availFloors
      .map(floor => (
        <KovaFloorplanGraphic
          key={`${floor.ID}-${floor.Index}`}
          incrementGraphicsReady={this.incrementGraphicsReady}
          communityRID={communityRID}
          modelRID={modelRID}
          selectedFloor={floor.ID}
          setStatus={this.setStatus}
          floorIndex={floor.Index}
          selectedOpts={allFloorSelOpts[floor.Index]}
          imgData={allFloorImageData[floor.Index]}
          filteredOptImgs={filteredOptImgs}
          isElevation={
            availFloors[floor.Index].Description.includes('Elevation')
          }
        />
      ));
  }

  incrementGraphicsReady = () => {
    this.setState(prevState =>
      ({ graphicsReady: prevState.graphicsReady + 1 }));
  }

  finishDownload = () => {
    const { toggleTriggerPrint } = this.props;
    this.setState({
      floorplanImgs: [],
      graphicsReady: 0,
      status: ''
    });
    toggleTriggerPrint();
  }

  resetPDF = () => (
    <BlobProvider document={this.createDocument()}>
      {({ loading }) => {
        if (!loading) {
          this.finishDownload();

          return true;
        }

        return '';
      }}
    </BlobProvider>
  );

  handleGeneratedPDF = async (file) => {
    try {
      const { roomId, socket } = this.props;
      const fileName = await this.uploadPDF(file);
      const fileLink = `https://${GlobalConfig.get(GlobalConfig.Key.S3_BUCKET)}.s3-${GlobalConfig.get(GlobalConfig.Key.S3_REGION)}.amazonaws.com/${fileName}`;

      const event = {
        data: fileLink,
        name: 'open pdf',
        roomId
      };
      socket.emit('open pdf', event);
    } catch (err) {
      // TODO: Replace console.error() with reusable Alert/Error component
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }

  uploadPDF = async (file) => {
    const { roomId } = this.props;
    const key = `docs/brochure-${roomId}.pdf`;
    const s3 = S3Utils.connect();
    await S3Utils.putObject('application/pdf', file, key, s3);

    return key;
  }

  // COPIED FROM SVGBUILDER TO ALLOW EASY TWEAKING
  findAllSelectedOptions(optionsData, commDetails) {
    const output = [];
    if (commDetails && commDetails.CommunityID) output.push(commDetails.CommunityID)

    Object.keys(optionsData).forEach((option) => {
      const currentOption = optionsData[option];
      if (currentOption.Type === 'MultiChoice') {
        currentOption.Choices.forEach((singleOpt) => {
          if (singleOpt.Selected) {
            if (
              singleOpt.ID.includes('cabs')
              || singleOpt.ID.includes('Chrome')
            ) {
              const splitOpt = singleOpt.ID.split('|')[0];
              output.push(splitOpt);
            } else {
              output.push(
                this.formatSelectedOption(singleOpt.ID)
              );
            }
          }
        });
      } else if (currentOption.Selected) {
        output.push(this.formatSelectedOption(currentOption.ID));
      }
    });

    this.setState({
      selectedOpts: output
    });

    return output;
  }

  formatSelectedOption(option) {
    return option.replace(/\|\|/g, '').toLowerCase();
  }

  render() {
    const { setDownloadURL } = this.props;
    const { floorplanImgs, status, allFloorSelOpts } = this.state;

    return (
      <>
        <ClientSettingsConsumer>
          {({
            theme, printToS3, showPriceOnPDF, kovaKeyMap
          }) => (
            floorplanImgs.length > 0 && (
              <BlobProvider document={this.createDocument(theme, showPriceOnPDF, kovaKeyMap)}>
                {({ blob, loading, url }) => {
                  if (!loading) {
                    if (printToS3) {
                      const file = new File([blob], 'brochure.pdf');
                      this.handleGeneratedPDF(file);
                      // window.open(url);
                    } else {
                      setDownloadURL(url);
                      //window.open(url);
                    }
                    this.finishDownload();
                    return true;
                  }

                  return '';
                }}
              </BlobProvider>
            )
          )}
        </ClientSettingsConsumer>
        {(status === 'floorplan-graphics' || status === 'pdf')
          && allFloorSelOpts
          && this.listFloorplanGraphics()
        }
        { status ? <ProgressBar /> : null }
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  modelRID: selectModelRID,
  configData: selectConfigData,
  communityRID: selectCommunityRID,
  communityDetails: selectCommunityDetails,
  availFloors: selectAvailFloors,
  basePrice: selectBasePrice,
  modelDetails: selectModelDetails,
  keyMap: selectKeyMap
});

const mapDispatchToProps = dispatch => ({
  toggleTriggerPrint: () => dispatch(toggleTriggerPrint()),
  setDownloadURL: downloadURL => dispatch(setDownloadURL(downloadURL)),
});


KovaPDFDownloader.propTypes = {
  roomId: PropTypes.string,
  socket: PropTypes.object.isRequired,
  modelRID: PropTypes.number.isRequired,
  communityRID: PropTypes.number.isRequired,
  configData: PropTypes.object.isRequired,
  availFloors: PropTypes.array.isRequired,
  filteredOptImgs: PropTypes.object.isRequired,
  basePrice: PropTypes.number.isRequired,
  communityDetails: PropTypes.object.isRequired,
  modelDetails: PropTypes.object.isRequired,
  elevationOptions: PropTypes.object.isRequired,
  toggleTriggerPrint: PropTypes.func.isRequired,
  setDownloadURL: PropTypes.func.isRequired,
};

KovaPDFDownloader.defaultProps = {
  roomId: undefined
};

export default connect(mapStateToProps, mapDispatchToProps)(KovaPDFDownloader);
