import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import styles from './ElevationSelect.module.css';
import SectionHeader from '../SectionHeader/SectionHeader';
import ElevationOption from '../ElevationOption/ElevationOption';

import {
  selectModelOptions,
  selectKeyMap
} from '../../redux/model/model.selectors';

const ElevationSelect = ({
  handleOptionItemClick,
  modelOptions,
  keyMap
}) => (
  modelOptions && modelOptions[keyMap.elevation] ? (
    <div className={styles.elevationSelect}>
      <div className={styles.elevationSelectHeader}>
        <SectionHeader title="Select Elevation" />
      </div>
      <div className={styles.elevationOptionContainer}>
        {
          modelOptions[keyMap.elevation].Choices.map(elev => (
            <ElevationOption
              key={`${elev.ID}_${elev.Text}`}
              elev={elev}
              handleOptionItemClick={handleOptionItemClick}
            />
          ))
        }
      </div>
    </div>
  ) : null
);

const mapStateToProps = createStructuredSelector({
  modelOptions: selectModelOptions,
  keyMap: selectKeyMap
});

ElevationSelect.propTypes = {
  handleOptionItemClick: PropTypes.func.isRequired,
  keyMap: PropTypes.object.isRequired,
  modelOptions: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(ElevationSelect);
