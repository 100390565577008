import * as jsonConfigData from '../../components/ColorSelector/selectorConfig/json/filemap.json';
import sidingConfig from './config/siding';
import foundationConfig from './config/foundation';
import shuttersConfig from './config/shutters';
import doorConfig from './config/door';
import garageConfig from './config/garage';

export const handleSequenceChange = (sequenceID, state) => {
  const { activeSequence } = state;
  let nextSequence;

  activeSequence !== sequenceID || activeSequence === ''
    ? nextSequence = sequenceID
    : nextSequence = '';

  if (sequenceID === 'selections' && !state.activePositionLeft) {
    return {
      activePositionLeft: 'left',
      activeSequence: nextSequence
    };
  }

  return {
    activeSequence: nextSequence
  };
};

export const setElevationKey = (selOpts) => {
  if (selOpts['ELEV-ELEV']) return 'ELEV-ELEV';
  if (selOpts['ELV-EXT']) return 'ELV-EXT';

  return 'ELEV-ELEV';
};

// Remove the period at the end of some Arbor & Silverthorne model names
export const editModelNamesWithPeriods = (modelName) => {
  let model = modelName;

  if (model[model.length - 1] === '.') {
    model = model.slice(0, model.length - 1);
  }

  return model;
};

const buildOptionTypes = (config) => {
  const optionTypes = [];

  config.forEach((item) => {
    if (!optionTypes.includes(item['Option Type'])) {
      optionTypes.push(item['Option Type']);
    }
  });

  return optionTypes;
};

const buildSidingOptions = (optionsConfig, type, typeConfig) => {
  const sidingGroup = sidingConfig.find(group => group.type === type);
  const sidingGroupDup = JSON.parse(JSON.stringify(sidingGroup));

  sidingGroupDup.options = typeConfig.map((item) => {
    const text = ['FC1', 'FC2'].includes(type)
      ? item['Option Name'].split('/')[0].split(':')[1].split('-')[1].trim()
      : item['Option Name'].split('/')[0].split(':')[1].trim();
    return {
      type,
      text,
      frame: item['Frame Number'],
      fileName: item['File Name']
    };
  });
  optionsConfig.siding.push(sidingGroupDup);
};

const buildFoundationOptions = (optionsConfig, type, typeConfig) => {
  const foundationGroup = foundationConfig.find(group => group.type === type);
  const foundationGroupDup = JSON.parse(JSON.stringify(foundationGroup));

  foundationGroupDup.options = typeConfig.map(item => ({
    type,
    text: item['Option Name'],
    frame: item['Frame Number'],
    fileName: item['File Name']
  }));
  optionsConfig.foundation.push(foundationGroupDup);
};

const buildShutterOptions = (optionsConfig, type, typeConfig) => {
  const shutterGroup = shuttersConfig.find(group => group.type === type);
  const shutterGroupDup = JSON.parse(JSON.stringify(shutterGroup));

  shutterGroupDup.options = typeConfig.map(item => ({
    type,
    text: item['Option Name'],
    frame: item['Frame Number'],
    fileName: item['File Name']
  }));
  optionsConfig.shutters.push(shutterGroupDup);
};

const buildDoorOptions = (optionsConfig, type, typeConfig) => {
  const doorGroup = doorConfig.find(group => group.type === type);
  const doorGroupDup = JSON.parse(JSON.stringify(doorGroup));

  doorGroupDup.options = typeConfig.map(item => ({
    type,
    text: item['Option Name'],
    frame: item['Frame Number'],
    fileName: item['File Name']
  }));
  optionsConfig.door.push(doorGroupDup);
};

const buildGarageOptions = (optionsConfig, type, typeConfig) => {
  const garageGroup = garageConfig.find(group => group.type === type);
  const garageGroupDup = JSON.parse(JSON.stringify(garageGroup));

  garageGroupDup.options = typeConfig.map(item => ({
    type,
    text: item['Option Name'],
    frame: item['Frame Number'],
    fileName: item['File Name']
  }));
  optionsConfig.garage.push(garageGroupDup);
};

export const generateOptionsConfig = (model, elevation) => {
  const formattedModel = model.replace(' ', '');
  const config = jsonConfigData.default[formattedModel][elevation.replace('elev', '')];
  const optionsConfig = {};

  // Generates list of all unique option types based on filemap.json
  // i.e. ["SV", "UV", "FC1", "FC2", "DS", "GS", "BS"]
  const optionTypes = buildOptionTypes(config);

  // Finds just list of sidingTypes -> ["SV", "UV", "FC1", "FC2"]
  const sidingTypes = sidingConfig.map(group => group.type);
  const shutterTypes = shuttersConfig.map(group => group.type);
  const foundationTypes = foundationConfig.map(group => group.type);
  const doorTypes = doorConfig.map(group => group.type);
  const garageTypes = garageConfig.map(group => group.type);

  optionTypes.forEach((type) => {
    // Generates an array of options with that Option Type
    const typeConfig = config.filter(item => item['Option Type'] === type);

    if (sidingTypes.includes(type) && !optionsConfig.siding) {
      optionsConfig.siding = [];
    }
    if (shutterTypes.includes(type) && !optionsConfig.shutters) {
      optionsConfig.shutters = [];
    }
    if (foundationTypes.includes(type) && !optionsConfig.foundation) {
      optionsConfig.foundation = [];
    }
    if (doorTypes.includes(type) && !optionsConfig.door) {
      optionsConfig.door = [];
    }
    if (garageTypes.includes(type) && !optionsConfig.garage) {
      optionsConfig.garage = [];
    }

    if (sidingTypes.includes(type)) {
      buildSidingOptions(optionsConfig, type, typeConfig);
    }
    if (shutterTypes.includes(type)) {
      buildShutterOptions(optionsConfig, type, typeConfig);
    }
    if (foundationTypes.includes(type)) {
      buildFoundationOptions(optionsConfig, type, typeConfig);
    }
    if (doorTypes.includes(type)) {
      buildDoorOptions(optionsConfig, type, typeConfig);
    }
    if (garageTypes.includes(type)) {
      buildGarageOptions(optionsConfig, type, typeConfig);
    }
  });

  return optionsConfig;
};

export const setOptionDefaults = optionsConfig => ({
  siding: optionsConfig.siding ? optionsConfig.siding[0].options[0] : null,
  foundation: optionsConfig.foundation ? optionsConfig.foundation[0].options[0] : null,
  shutters: optionsConfig.shutters ? optionsConfig.shutters[0].options[0] : null,
  door: optionsConfig.door ? optionsConfig.door[0].options[0] : null,
  garage: optionsConfig.garage ? optionsConfig.garage[0].options[0] : null
});
