/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-alert */
import React, { Component,useContext } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Api from '../../utils/Api/Api';
import KovaData from '../../utils/KovaData/KovaData';
import styles from './SaveForm.module.css';

import { connect } from 'react-redux';
import { selectCommunityRID, selectDropdownCommunityRID, selectDirectory, } from '../../redux/community/community.selectors';
import { selectModelRID, selectConfigData } from '../../redux/model/model.selectors';
import { toggleTriggerPrint } from '../../redux/app/app.actions';
import { createStructuredSelector } from 'reselect';
import { ClientSettingsConsumer } from '../../context/ClientSettingsContext';
import GlobalConfig from '../../utils/GlobalConfig';

class SaveForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      city: '',
      touched: {
        firstName: false,
        lastName: false,
        email: false,
      },
      optionsForEmail: null
    };
  }

  async componentDidMount() {
    const {
      communityRID,
      modelRID,
      configData
    } = this.props;

    const dataForEmail = await Api.postConfigDataUpdate(
      communityRID,
      modelRID,
      -1,
      'False',
      configData.SelectedOptions
    );

    const optionsForEmail = KovaData.createSelectedOptionsObj(dataForEmail.Options);

    this.setState({ optionsForEmail });
  }

  handleUserInput = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  displayMessage = (message) => {
    setTimeout(() => {
      alert(message);
    }, 250);
  }

  handleBlur = field => (evt) => {
    const {
      touched
    } = this.state;
    this.setState({
      touched: { ...touched, [field]: true },
    });
  }

  validateFirstName = (firstName) => {
    const {
      touched
    } = this.state;

    return (!firstName.length && touched.firstName === true) ? false : true;
  }

  validateLastName = (lastName) => {
    const {
      touched
    } = this.state;

    return (!lastName.length && touched.lastName === true) ? false : true;
  }

  validateEmail = email => (
    /\S+@\S+\.\S+/.test(email)
  )

  validateForm = (firstName, lastName, email) => (
    this.validateFirstName(firstName)
    && this.validateLastName(lastName)
    && this.validateEmail(email)
  )

  emailForm = async (e) => {
    e.preventDefault();
    const {
      firstName,
      lastName,
      email,
      phone,
      city,
      optionsForEmail
    } = this.state;

    const { toggleForm } = this.props;

    const {
      communityDirectory,
      toggleTriggerPrint,
      configData,
      dropdownCommunityRID
    } = this.props;

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    };

    let selectedCommName = '';
    if (communityDirectory) {
      const selectedComm = communityDirectory.find(comm => comm.rid === dropdownCommunityRID);
      selectedCommName = selectedComm.name;
    }

    const object = {};
    object.firstname = firstName;
    object.lastname = lastName;
    object.phone = phone;
    object.email = email;
    object.community = selectedCommName;
    object.options = optionsForEmail;
    object.modelName = configData.Name;
    object.city = city;

    const hubspotConnectionURL = GlobalConfig.get(GlobalConfig.Key.HUBSPOT_CONNECTION_URL);
    if (hubspotConnectionURL) {
      axios.post(hubspotConnectionURL, object, config)
        .then((res) => {
          toggleForm();
          toggleTriggerPrint();
        });
    } else {
      console.log("Contact information not sent!");
      toggleForm();
      toggleTriggerPrint();
    }
  }

  render() {
    const {
      firstName,
      lastName,
      email,
      touched,
      phone,
      city
    } = this.state;

    const { toggleForm } = this.props;

    const isValid = this.validateFirstName(firstName)
    && this.validateLastName(lastName)
    && this.validateEmail(email);

    return (
      <ClientSettingsConsumer>
        {({ cityDropdownList, showCityDropdownList }) => (
          <form className={styles.saveForm}>
            <p>Please enter your information below</p>
            <input
              className={`${this.validateFirstName(firstName)
                ? styles.formInput
                : styles.error}
              `}
              type="text"
              name="firstName"
              placeholder="First Name (Required)"
              value={firstName}
              onBlur={this.handleBlur('firstName')}
              onChange={this.handleUserInput}
            />
            <br />
            <input
              className={`${this.validateLastName(lastName)
                ? styles.formInput
                : styles.error}
              `}
              type="text"
              name="lastName"
              placeholder="Last Name (Required)"
              value={lastName}
              onBlur={this.handleBlur('lastName')}
              onChange={this.handleUserInput}
            />
            <br />
            <input
              className={`${!this.validateEmail(email) && touched.email === true
                ? styles.error
                : styles.formInput}
              `}
              type="text"
              name="email"
              placeholder="Email (Required)"
              value={email}
              onBlur={this.handleBlur('email')}
              onChange={this.handleUserInput}
            />
            <br />
            <input
              className={styles.formInput}
              type="text"
              name="phone"
              placeholder="Phone Number"
              value={phone}
              onChange={this.handleUserInput}
            />
            <br />
            { showCityDropdownList && (
              <select
                className={styles.formInput}
                name="city"
                value={city}
                onChange={this.handleUserInput}
              >
                { cityDropdownList.map((city) => {
                  return <option key={city} value={city}>{city}</option>;
                })}
              </select>
            )}
            <br />
            <div className={styles.buttonRow}>
              <button
                type="button"
                className={`${isValid ? styles.saveButton : styles.saveButtonDisabled}`}
                onClick={this.emailForm}
              >
                Save
              </button>
              <button
                type="button"
                className={styles.cancelButton}
                onClick={toggleForm}
              >
                Cancel
              </button>
            </div>
          </form>
        )}
      </ClientSettingsConsumer>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  configData: selectConfigData,
  modelRID: selectModelRID,
  communityRID: selectCommunityRID,
  dropdownCommunityRID: selectDropdownCommunityRID,
  communityDirectory: selectDirectory
});

const mapDispatchToProps = dispatch => ({
  toggleTriggerPrint: () => dispatch(toggleTriggerPrint()),
});

SaveForm.propTypes = {
  toggleForm: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(SaveForm);