import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { settings, ClientSettingsProvider, ClientSettingsConsumer } from './context/ClientSettingsContext';
import './index.css';
import App from './components/App/App';
import * as serviceWorker from './serviceWorker';

import store from './redux/store';
import GlobalConfig from './utils/GlobalConfig';


fetch('./envconfig.json')
  .then(response => response.json())
  .then((data) => {
    GlobalConfig.setConfig(data);

    ReactDOM.render(
      <Provider store={store}>
        <ClientSettingsProvider value={settings[GlobalConfig.get(GlobalConfig.Key.CLIENT_NAME)]}>
          <ClientSettingsConsumer>
            {({
              defaultCommunityRID,
              defaultModelRID,
              showInitialLoadingProgress,
              touchscreenEnabled,
              useKovaPDFDownloader,
              showKovaStage,
              loadModelRules,
              colorSelectorEnabled,
              communityDropdownList,
              showCommunityDropdownList,
              clientFloorOnAppLoad
            }) => (
              <App
                defaultCommunityRID={defaultCommunityRID}
                defaultModelRID={defaultModelRID}
                showInitialLoadingProgress={showInitialLoadingProgress}
                touchscreenEnabled={touchscreenEnabled}
                useKovaPDFDownloader={useKovaPDFDownloader}
                showKovaStage={showKovaStage}
                loadModelRules={loadModelRules}
                colorSelectorEnabled={colorSelectorEnabled}
                communityDropdownList={communityDropdownList}
                showCommunityDropdownList={showCommunityDropdownList}
                clientFloorOnAppLoad={clientFloorOnAppLoad}
              />
            )}
          </ClientSettingsConsumer>
        </ClientSettingsProvider>
      </Provider>,
      document.getElementById('root')
    );

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
  });
