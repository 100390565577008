/* eslint-disable no-unused-vars */
import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Stage from '../Stage/Stage';
import KovaStage from '../KovaStage/KovaStage';
import CadFloorplanRenderer from '../CadFloorplanRenderer/CadFloorplanRenderer';
import ViewToggle from '../ViewToggle/ViewToggle';
import ButtonPanel from '../ButtonPanel/ButtonPanel';
import SelectionsMenu from '../SelectionsMenu/SelectionsMenu';
import RightSideMenu from '../RightSideMenu/RightSideMenu';
import LeftSideMenu from '../LeftSideMenu/LeftSideMenu';
import MenuPanel from '../ColorSelector/MenuPanel/MenuPanel';
import ExteriorStage from '../ColorSelector/ExteriorStage/ExteriorStage';
import BrandLogo from '../../shared/BrandLogo/BrandLogo';
import RightMenuTabs from '../../shared/RightMenuTabs/RightMenuTabs';
import HomeDetails from '../../shared/HomeDetails/HomeDetails';
import ElevationSelect from '../../shared/ElevationSelect/ElevationSelect';
import SideMenuButton from '../../shared/SideMenuButton/SideMenuButton';
import ModelName from '../ModelName/ModelName';
import Footer from '../Footer/Footer';
import styles from './Dashboard.module.css';
import { ClientSettingsContext } from '../../context/ClientSettingsContext';

import bedIcon from '../../img/bed_icon.svg';
import bedIconB from '../../img/bed_icon_b.svg';
import bathIcon from '../../img/bath_icon.svg';
import bathIconB from '../../img/bath_icon_b.svg';
import squareFootIcon from '../../img/square_foot_icon.svg';
import squareFootIconB from '../../img/square_foot_icon_b.svg';
import dollarIcon from '../../img/dollar_icon.svg';
import dollarIconB from '../../img/dollar_icon_b.svg';

import {
  selectShowElevation,
  selectModelRules
} from '../../redux/model/model.selectors';

const Dashboard = ({
  clientFloorOnAppLoad,
  handleClearSelections,
  handleFloorToggle,
  handleOptionItemClick,
  activePositionLeft,
  activeSequence,
  isActiveAdult,
  model,
  resetOptions,
  resetOptionsConfig,
  filteredOptImgs,
  quickMoveIn,
  modelDetails,
  showElevation,
  modelRules
}) => {
  const dispatch = useDispatch();
  const showSideMenu = useSelector(state => state.app.showSideMenu);
  const ClientSettings = useContext(ClientSettingsContext);
  const {
    touchscreenEnabled,
    colorSelectorEnabled,
    showKovaStage,
    showElevationSelect,
    allowFloorplanZoom
  } = ClientSettings;

  const toggleResetView = useCallback(
    () => dispatch({ type: 'TOGGLE_RESET_VIEW' }),
    [dispatch]
  );

  return (
    <div
      className={
        touchscreenEnabled
          ? styles.touchscreenDashboard
          : styles.webDashboard
      }
    >
      {!touchscreenEnabled && (
        <div className={`${styles.modelNameContainer}`}>
          <ModelName />
        </div>
      )}
      <div className={`${styles.stageContainer}`}>
        {/* This renders the old stage component with SVG layering - still used for Arbor */}
        {!showElevation && !touchscreenEnabled && !showKovaStage && (
          <Stage />
        )}
        {/* Renders KovaStage which uses Kova graphics and Kova option image conditionals - Oakwood/Silverthorne */}
        {/* Also renders the elevation images */}
        {((showKovaStage && !colorSelectorEnabled)
          || (showKovaStage && colorSelectorEnabled && !modelRules.loadCadBuilder))
          && (
            <KovaStage
              filteredOptImgs={filteredOptImgs}
              showKovaStage={showKovaStage}
              colorSelectorEnabled={colorSelectorEnabled}
              showElevation={showElevation}
            />
          )
        }
        {/* Work in progress - will ultimately render graphics imported from CAD */}
        {colorSelectorEnabled && !showElevation && modelRules.loadCadBuilder && (
          <CadFloorplanRenderer
            filteredOptImgs={filteredOptImgs}
            showKovaStage={showKovaStage}
          />
        )}
        {/* This will render the Color Selector when necessary */}
        {showElevation && colorSelectorEnabled && (
          <ExteriorStage />
        )}
      </div>
      {!touchscreenEnabled && (
        <div className={styles.leftMenuContainer}>
          <LeftSideMenu>
            <BrandLogo />
            <HomeDetails
              handleFloorToggle={handleFloorToggle}
            />
            {showElevationSelect
              && (
                <ElevationSelect
                  handleOptionItemClick={handleOptionItemClick}
                />
              )
            }
          </LeftSideMenu>
        </div>
      )}
      {showSideMenu && (
        <>
          <div
            className={`${styles.rightMenuContainer} ${styles.unselectable}`}
            style={{
              justifyContent: `${
                touchscreenEnabled ? 'flex-end' : 'flex-start'
              }`,
              // borderBottom: `${
              //   touchscreenEnabled ? '1px solid black' : '1px solid black'
              // }`,
              flexDirection: `${touchscreenEnabled ? 'none' : 'column'}`,
              alignItems: `${touchscreenEnabled ? 'none' : 'center'}`
            }}
          >
            <RightSideMenu>
              {colorSelectorEnabled && (
                <div className={styles.rightMenuTabContainer}>
                  <RightMenuTabs
                    clientFloorOnAppLoad={clientFloorOnAppLoad}
                    handleFloorToggle={handleFloorToggle}
                  />
                </div>
              )}
              {allowFloorplanZoom && (
                <div className={styles.resetSideMenuButtonContainer}>
                  <SideMenuButton
                    buttonText="reset view"
                    clickHandler={toggleResetView}
                  />
                </div>
              )}
              {!showElevation || !colorSelectorEnabled ? (
                <div className={styles.rightMenuContainer}>
                  <SelectionsMenu
                    handleOptionItemClick={handleOptionItemClick}
                    quickMoveIn={quickMoveIn}
                  />
                </div>
              ) : (
                <div className={styles.optionListContainer}>
                  <MenuPanel
                    position="left"
                    headerText="Model Options"
                    activePosition={activePositionLeft}
                    activeSequence={activeSequence}
                    model={model}
                    resetOptions={resetOptions}
                    resetOptionsConfig={resetOptionsConfig}
                  />
                </div>
              )}
              {!touchscreenEnabled && (
                <div className={styles.clearSideMenuButtonContainer}>
                  <SideMenuButton
                    buttonText="clear all"
                    clickHandler={handleClearSelections}
                  />
                </div>
              )}
            </RightSideMenu>
          </div>
          {touchscreenEnabled && (
            <div className={`${styles.buttonPanelContainer}`}>
              <ButtonPanel handleClearSelections={handleClearSelections} />
            </div>
          )}
        </>
      )}
      <div className={`${styles.viewToggleContainer}`}>
        <ViewToggle handleFloorToggle={handleFloorToggle} isActiveAdult={isActiveAdult} />
      </div>
      {!touchscreenEnabled && (
        <div className={`${styles.footerContainer}`}>
          <Footer />
        </div>
      )}
    </div>
  );
};

Dashboard.defaultProps = {
  clientFloorOnAppLoad: null,
  model: null,
  resetOptions: null,
  resetOptionsConfig: null,
  activePositionLeft: null,
  activeSequence: null,
  filteredOptImgs: [],
  quickMoveIn: false,
  modelRules: {}
};

Dashboard.propTypes = {
  clientFloorOnAppLoad: PropTypes.number,
  quickMoveIn: PropTypes.bool,
  model: PropTypes.string,
  resetOptions: PropTypes.func,
  resetOptionsConfig: PropTypes.func,
  filteredOptImgs: PropTypes.array,
  handleOptionItemClick: PropTypes.func.isRequired,
  handleFloorToggle: PropTypes.func.isRequired,
  showElevation: PropTypes.bool.isRequired,
  handleClearSelections: PropTypes.func.isRequired,
  activePositionLeft: PropTypes.string,
  activeSequence: PropTypes.string,
  modelRules: PropTypes.object,
};

const mapStateToProps = createStructuredSelector({
  showElevation: selectShowElevation,
  modelRules: selectModelRules
});

export default connect(mapStateToProps)(Dashboard);
